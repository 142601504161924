<template>
  <div title="" v-show="show" append-to-body class="deleteDialog">
    <div class="deleteDialogMask"></div>
    <div class="Pupcon" v-if="type != 1">
      <div class="pupTop">
        <div class="Title">
          <img src="@/images/errImg.png" alt="" />
          <span class="dialogTitle">{{ content }}</span>
        </div>
        <p class="dialogContent">{{ confirmContent }}</p>
      </div>
      <span slot="footer" class="dialogFooter">
        <div @click="cancelDialog" class="cancelButton btn">
          {{ $t("machine.txt74") }}
        </div>
        <div type="primary" @click="confirmDialog" class="confirmButton btn">
          {{ $t("machine.txt75") }}
        </div>
      </span>
    </div>
    <div class="PupconTpis" v-if="type == 1">
      <div class="hiddenImg">
        <img src="@/images/hidden.png" alt="" @click="cancelDialog" />
      </div>
      <div class="pupTop">
        <div class="Title">
          <img src="@/images/errImg.png" alt="" />
          <span class="dialogTitle">{{ content }}</span>
        </div>
        <p class="dialogContent">{{ confirmContent }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CommonDialog",
  props: ["dialogVisible", "content", "confirmContent", "type"],
  data() {
    return {
      show: this.dialogVisible,
    };
  },
  watch: {
    dialogVisible: {
      //监听提花意匠图
      handler(newVal, oldVal) {
        this.show = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    cancelDialog() {
      this.$emit("cancelDialog", false);
    },
    confirmDialog() {
      this.$emit("confirmDialog", true);
    },
  },
};
</script>
<style lang="less">
@import "./CommonDialog.less";
</style>