export default {
  namespaced: true,
  state: {
    oneSidedata: {},//单面数据
    clearType: [0, 0, 0, 0],//单双面是否清空
    designCloth: {},//模型贴图
    loginType: 0,//登入状态
    Pageurltype: 0,//染整首页数据状态
    hzClearType: 0,//染整是不需要清空
    firstRz: 0,//是否第一次染整
    languageType: localStorage.getItem('languageSet') || 'zh',
    avatar: 0,//当前头像
    userInfo: {
      avatar: '',
      name: '',
    },
    guideType: false,
  },
  mutations: {
    setlanguageType(state, value) {
      state.languageType = value;
      // this.$i18n.locale = lang;
    },
    setavatar(state, value) {
      state.avatar = value;
      // this.$i18n.locale = lang;
    },
    setuserInfo(state, value) {
      state.userInfo = value;
    },
    setOneSidedata(state, value) {
    },
    setClearType(state, value) {
      state.clearType = value;
    },
    setDesignCloth(state, value) {
      state.designCloth = value;
    },
    outLogin(state, value) {
      state.loginType = value;
    },
    setPageurltype(state, value) {
      state.Pageurltype = value;
    },

    sethzClearType(state, value) {
      state.hzClearType = value;
    },

    setfirstRz(state, value) {
      state.firstRz = value;
    },
    setguideType(state, value) {
      state.guideType = value;
    },

  },
  actions: {},
  getters: {},
};
