/*
 * @Description: 历史数据源列表
 */

import { POST } from "@/axios/axios.js";
import API from "../api";

// 获取织布历史
export const searchHistory = (param) => {
  return POST(API.api_searchHistory, param);
};

//织布历史修改
export const editHistory = (param) => {
  return POST(API.api_editHistory, param);
};

//织布历史删除
export const delHistory = (param) => {
  return POST(API.api_delHistory, param);
};

//织布历史下载
export const downHistory = (param) => {
  return POST(API.api_downHistory, param);
};

//织布历史下载
export const getClothDetail = (param) => {
  return POST(API.api_getClothDetail, param);
};
//获取布料导出选择
export const getClothExportSelect = (param) => {
  return POST(API.api_getClothExportSelect, param);
};
//获取织法类目数据
export const getWeaveCategoryList = (param) => {
  return POST(API.api_getWeaveCategoryList, param);
};

//获取织法类目数据
export const getRenderStatus = (param) => {
  return POST(API.api_getRenderStatus, param);
};
