export default {
    namespaced: true,
    state: {
        numList: [10, 12, 15]
    },
    mutations: {
        deleteAddName(state, value) {
            if (value == 1) {
                state.numList.shift();
            } else {
                state.numList.pop();
            }
        },
        addName(state, value) {
            if (value == 1) {
                state.numList.unshift(18);
            }else {
                state.numList.push(30);
            }
        }
    },
    actions: {
        addName(conText, value) {
            conText.commit('addName', value);
        }
    },
    getters: {
        addName(state) {
            var arr = [];
            state.numList.forEach(item => {
                arr.push({ name: '李四', age: item })
            })
            return arr;
        }
    }
}