
const info = {
    ZH: {  //中文翻译
        txt1: "品号",
        txt2: "品名",
        txt3: "客户名称",
        txt4: "日期",
        txt5: "纱线信息",
        txt6: "序号",
        txt7: "颜色",
        txt8: "色号",
        txt9:  "成分",
        txt10: "纱支",
        txt11: "面料信息",
        txt12: "画布尺寸",
        txt13: "客户名称",
        txt14: "克重",
        txt15: "针床类型",
        txt16: "提花机号",
        txt17: "创建日期",
        txt18: "成品要求",
        txt19: "水洗标准",
        txt20: "干洗标准",
        txt21: "烘干标准",
        txt22: "晾干标准",
        txt23: "漂白标准",
        txt24: "熨烫标准",
        txt25: "花型意匠图",
        txt26: "面料效果图",
        txt27: "正面平铺图",
        txt28: "反面平铺图",
        txt29: "几何体",
        txt30: "男款展示",
        txt31: "女款展示",
        txt32: "基本信息",
        txt33: "导出中",
        txt34: "结构参数",
        txt35: "图样",
        txt36: "尺寸",
        txt37: "模型",
    },
    EN: {  //英文翻译
        txt1: "Product No.",
        txt2: "Product Name",
        txt3: "Client Name",
        txt4: "Date",
        txt5: "Yarn Info",
        txt6: "Serieal No.",
        txt7: "Color",
        txt8: "Color Code",
        txt9: "Component",
        txt10: "Yarn Count",
        txt11: "Fabric information",
        txt12: "Canvas Size",
        txt13: "Client Name",
        txt14: "Weight",
        txt15: "Needle Bed Type",
        txt16: "Jacquard Loom No.",
        txt17: "Date",
        txt18: "Finished Product Warning",
        txt19: "Finished Product Warning",
        txt20: "Dry Cleaning Standard",
        txt21: "Tumble Drying Standard",
        txt22: "Air Drying Standard",
        txt23: "Bleaching Standard",
        txt24: "Ironing Standard",
        txt25: "Knit",

        txt26: "Fabric renderings",
        txt27: "front",
        txt28: "Back",
        txt29: "geometry",
        txt30: "men’s display",
        txt31: "women’s model display",
        txt32: "Basic Information",
        txt33: "Exporting",
        txt34: "Structure parameters",
        txt35: "Pattern",
        txt36: "Size",
        txt37: "Model",

    }
}
export default info  //需要暴露出去