
import Login from "./login"
import User from './user'
import modelSwitching from './modelSwitching'
import machine from './machine'
import left from './left'
import renderResults from './renderResults'
import pup from './pup'
import historyList from './historyList'
import historyDetail from './historyDetail'
import downloadPage from './downloadPage'
import head from './head'


export default {
    Login,
    User,
    modelSwitching,
    machine,
    left,
    renderResults,
    pup,
    historyList,
    historyDetail,
    downloadPage,
    head
}
// {{$t('user.login.wxLogin')}}
// this.$t('video.videoPlay.warningText')