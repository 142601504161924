import Vue from "vue";
import { Message } from 'element-ui'

let messageDom = null

// 状态type 1:成功 2:警告 3:错误
Vue.prototype.$myMessage = function (msg, type) {
    let img = '';
    let title = ''
    if (type == 1) {
        img = require('../images/success.png');
        title = "成功"
    } else if (type == 2) {
        img = require('../images/warning.png');
        title = "警告"
    } else {
        img = require('../images/error.png');
        title = "错误"
    }
    
    if (messageDom) {
        messageDom.close()
    }

    messageDom = Message({
        dangerouslyUseHTMLString: true,
        iconClass: 'none',
        message: `
        <div class="msgBox">
            <div class="msgBoxTop">
                <img src="${img}" alt="" />
                <div>${title}</div>
            </div>
            <div class="msgTips">${msg}</div>
       </div>
      `
    })
}
