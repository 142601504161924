const info = {
    ZH: {  //中文翻译
        txt1: "虚拟织布",
        txt2: "织布历史",
        txt3: "个人中心",
        txt4: "新手指引",
        txt5: "视频教程",
        txt6: "生成规则说明",
        txt7: "下载",
        txt8: "账号信息",
        txt9: "数据将会清空，是否继续",
        txt10: "切换设备",
        txt11: "帮助中心",
        txt12: "账号已禁用",
        txt13: "账号已被禁用，继续使用请联系运营人员",
        txt14: "账号已过期",
        txt15: "已过账号有效期，继续使用请联系运营人员",
    },
    EN: {  //英文翻译
        txt1: "Virtual Knitting",
        txt2: "Knitting Record",
        txt3: 'Personal Center',
        txt4: "Beginners’ Guide",
        txt5: "Tutorials",
        txt6: "Rules and Instructions  for Generation",
        txt7: "Download",
        txt8: "Account information",
        txt9: "The data will be cleared. Do you want to continue?",
        txt10: "Switch device",
        txt11: "Help Center",
        txt12: "The account has been disabled",
        txt13: "To continue using it, please contact the operations personnel.",
        txt14: "Account Expired",
        txt15: "The account has exceeded its validity period. To continue using, please contact the operations personne",
    }
}
export default info  //需要暴露出去