<template>
  <div class="leftNav">
    <div class="logo">
      <img src="@/images/newleftNavLogo.png" alt="" />
    </div>
    <div class="btn">
      <div v-for="(item, index) in iconList" :key="index" class="icon" @click="addActive(index)"
        :style="index == 0 ? 'margin:0' : ''">
        <div class="imgBox" :style="!item.show ? 'background:rgba(255,255,255,0.1)' : ''">
          <img :src="item.url1" alt="" v-if="item.show" />
          <img :src="item.url2" alt="" v-if="!item.show" />
        </div>
        <span :style="!item.show ? 'color:#fff;' : ''" class="bottomTxt">
          {{ item.txt }}</span>
      </div>
    </div>
    <div class="footer btn">
      <el-popover placement="right" ref="popover" width="191" popper-class="popoverStyle" trigger="click">
        <div class="leftComplist" @click="openrulePup(2)">
          <span>{{ $t("left.txt5") }}</span>
        </div>
        <div class="icon guide" @click="addActive(3)" slot="reference">
          <div class="imgBox" :style="!footericonList[0].show ? 'background:rgba(255,255,255,0.1)' : ''
            " v-popover:popover>
            <img :src="footericonList[0].url2" alt="" v-if="footericonList[0].show" />
            <img :src="footericonList[0].url1" alt="" v-if="!footericonList[0].show" />
          </div>
          <span :style="!footericonList[0].show ? 'color:#fff;' : ''" class="bottomTxt HelpTxt">{{ $t("left.txt11")
          }}</span>
        </div>
      </el-popover>
    </div>
    <div class="videoBox" v-if="VideotType">
      <div class="videoBoxMask" @click="hiddenPup(2)"></div>
      <video-player class="video-player vjs-custom-skin" :options="playerOptions"></video-player>
    </div>
    <div class="mask" v-show="((!footericonList[0].show && !$store.state.index.guideType) ||
        rulePupType) &&
      !VideotType
      " @click="hiddenPup(2)"></div>
  </div>
</template>

<script>
import { latelyUseColor } from "@/api/history/history";
export default {
  data() {
    return {
      iconList: [
        {
          show: false,
          url1: require("../../newImg/leftw1.png"),
          url2: require("../../newImg/left1.png"),
          txt: this.$t("left.txt1"),
        },
        {
          show: true,
          url1: require("../../newImg/leftw2.png"),
          url2: require("../../newImg/left2.png"),
          txt: this.$t("left.txt2"),
        },
        {
          show: true,
          url1: require("../../newImg/leftw4.png"),
          url2: require("../../newImg/left4.png"),
          txt: this.$t("left.txt3"),
        },
      ],
      footericonList: [
        {
          show: true,
          url1: require("../../newImg/left3.png"),
          url2: require("../../newImg/leftw3.png"),
        },
      ],
      rulePupType: false,
      VideotType: false,
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "https://vsk-vkm.oss-cn-shanghai.aliyuncs.com/copy_758E155D-2604-4025-A275-2F3F0BB5A4C1%202.mp4", // url地址;
          },
        ],
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      },
      fullPath: "",
      path: "",
      zoomD: 100,
      originx: 0,
      originy: 0,
    };
  },
  methods: {
    gunlun(e) {
      this.zoomD -= e.deltaY / 100;
      if (this.zoomD < 50) {
        this.zoomD = 50;
      }

      if (this.zoomD > 100) {
        this.zoomD = 100;
      }
      return;
      e.preventDefault();
      // 获取元素
      var left = document.querySelector(".left");
      var img = document.querySelector(".img");
      var x = e.pageX - img.offsetLeft;
      var y = e.pageY - img.offsetTop;

      // 添加鼠标移动事件
      left.addEventListener("mousemove", move);
      function move(e) {
        img.style.left = e.pageX - x + "px";
        img.style.top = e.pageY - y + "px";
      }
      // 添加鼠标抬起事件，鼠标抬起，将事件移除
      img.addEventListener("mouseup", function () {
        left.removeEventListener("mousemove", move);
      });
      // 鼠标离开父级元素，把事件移除
      left.addEventListener("mouseout", function () {
        left.removeEventListener("mousemove", move);
      });
    },
    // 缩放图片
    rollImg() {
      /* 获取当前页面的缩放比 若未设置zoom缩放比，则为默认100，原图大小 */
      if (this.zoomD <= 50) {
        this.zoomD = 50;
      }
      var zoom = this.zoomD + (event.detail * 40) / 12;
      /*根据修改transform 改变图片缩放大小 */
      this.$refs.imgDiv.style.transform = "scale(" + zoom / 100 + ")";
      this.zoomD = zoom;
    },

    openrulePup(type) {
      this.hiddenPup(1);
      this.fullPath = this.$route.fullPath;
      this.path = this.$route.path;
      if (type == 2) {
        this.VideotType = true;
        this.hiddenLeft();
      }
      this.$refs.popover.doClose();
    },

    hiddenLeft() {
      // this.$store.commit("index/setclearPageTimeoutType", true);
      this.$store.commit("index/setguideType", false);
      if (this.fullPath.includes("/oneIndex")) {
        this.$router.push({
          path: this.path,
          query: {
            machineType: this.$store.state.index.indexType,
            clearType: 1,
            guideType: false,
          },
        });
      } else {
        this.$router.push({
          path: this.fullPath,
        });
      }
    },
    hiddenPup(type) {
      if (type == 1) {
      } else if (type == 2) {
        this.setpage(this.$route);
      } else {
        if (type != 4) {
          this.$set(this.footericonList[0], "show", true);
        }
      }
      this.$store.commit("index/setguideType", false);
      this.VideotType = false;
    },

    SetActiveIcon(activeIcon, type) {
      if (activeIcon == 3) {
        this.$set(this.footericonList[0], "show", false);
        if (type != 1) {
          this.$refs.popover.doShow();
        }
      }

      this.iconList.forEach((item, index) => {
        if (activeIcon == index) {
          item.show = false;
        } else {
          item.show = true;
        }
      });

      this.footericonList.forEach((item, index) => {
        if (activeIcon == index + 3) {
          item.show = false;
        } else {
          item.show = true;
        }
      });
    },
    addActive(index) {
      // if (index != 3 && index != 0) {
      //   if (index != 0) {
      //     this.hiddenPup(this.rulePupType ? 4 : "");
      //   } else {
      //   }
      // }
      // this.rulePupType = false;
      this.hiddenPup(this.rulePupType ? 4 : "");
      switch (index) {
        case 0:
          if (this.$store.state.index.guideType) {
            this.$store.commit("index/setclearPageTimeoutType", true);
            this.$store.commit("index/setguideType", false);
            this.$set(this.iconList[0], "show", true);
            this.SetActiveIcon(index);
            console.log(23132)
            if (localStorage.getItem("leftNavtype") == 1) {
              this.$router.push({
                path: "/",
                query: {
                  machineType: 4,
                  clearType: 1,
                  guideType: false,
                },
              });
            } else {
              this.$router.push({
                path: "/oneIndex",
                query: {
                  machineType: 4,
                  clearType: 1,
                  guideType: false,
                },
              });
            }
          } else {
            this.SetActiveIcon(index);
            if (localStorage.getItem("leftNavtype") == 1) {
              this.$router.push({
                path: "/",
                query: {
                  machineType: 4,
                  clearType: 1,
                  guideType: false,
                },
              });
            } else {
              this.$router.push({
                path: "/oneIndex",
                query: {
                  machineType: 4,
                  clearType: 1,
                  guideType: false,
                },
              });
            }
          }
          break;
        case 1:
          this.SetActiveIcon(index);
          this.$router.push("/history");
          break;
        case 2:
          this.SetActiveIcon(index);
          this.$router.push("/userIndex");
          break;
        case 3:
          this.SetActiveIcon(index);
          break;
        case 4:
          this.SetActiveIcon(index);
          this.$router.push("/userIndex");
          break;
      }
    },
    setpage(newVal) {
      if (newVal.path.includes("/oneIndex") || newVal.path == "/"||newVal.path.includes("/modelSwitching")) {
        if (!this.$store.state.index.guideType) {
         
          if (!this.rulePupType) {
            this.SetActiveIcon(0);
          } else {
            this.$set(this.footericonList[0], "show", false);
          }
        } else {
          if (this.$store.state.index.LoginType) {
          } else {
            this.$set(this.footericonList[0], "show", false);
          }
        }
      }
      if (newVal.path.includes("/history")) {
        this.SetActiveIcon(1);
      }
      if (newVal.path.includes("/Detailpage")) {
        if (newVal.query.type != 2) {
          this.SetActiveIcon(1);
        } else {
          this.SetActiveIcon(2);
        }
      }
      if (newVal.path.includes("/entry")) {
        this.SetActiveIcon(2);
      }
      if (newVal.path.includes("/userIndex")) {
        this.SetActiveIcon(2);
      }
    },
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        this.setpage(newVal);
        if (
          oldVal &&
          (oldVal.path.includes("index") || oldVal.path == "/") &&
          oldVal.meta &&
          oldVal.meta.title == "首页" &&
          !newVal.path.includes("loginUp")
        ) {
          let yarnColor = JSON.parse(localStorage.getItem("latelyColor"));
          /*  if (yarnColor) {
            latelyUseColor({ type: 2, colorArray: yarnColor }).then((res) => {
              if (res.code == 0) {
              }
            });
          } else {
            latelyUseColor({ type: 2, colorArray: [] }).then((res) => {
              if (res.code == 0) {
              }
            });
          } */
        }
      },
      deep: true,
      // 代表在wacth里声明了这个方法之后立即先去执行handler方法
      immediate: true,
    },
    "$store.state.index.guideType"(newVal, Val) {
      if (newVal) {
        if (localStorage.getItem("guideType") == 1) {
          this.$set(this.footericonList[0], "show", false);
          this.$set(this.iconList[0], "show", true);
          localStorage.setItem("guideType", 0);
        } else {
          this.SetActiveIcon(3, 1);
        }
      } else {
        if (!this.rulePupType) {
          this.$set(this.footericonList[0], "show", true);
        }
        localStorage.setItem("clearType", 1);
      }
    },

    "$store.state.index.languageType"(newVal, Val) {
      this.iconList.forEach((element, index) => {
        this.$set(this.iconList[index], "txt", this.$t(`left.txt${index + 1}`));
      });
    },
  },
  mounted() {
    // this.addActive(0)
  },
};
</script>

<style lang="less" scoped>
@import url("./leftNav.less");
</style>

<style lang="less">
.leftComplist {
  min-height: 53px;
  width: 100%;
  border-bottom: 1px solid #f1efef;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #101426;
    line-height: 25px;
    text-align: center;
    margin-top: 13px;
    margin-bottom: 13px;
  }
}

.popoverStyle {
  // padding: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  // .guide {
  //   width: 20px;
  //   height: 20px;
  // }
}
</style>
