/*
 * @Description: 首页数据源列表
 */

import { POST } from "@/axios/axios.js";
import API from "../api";

// 登录
export const userLogin = (param) => {
  return POST(API.api_login, param);
};

// 获取短信验证码
export const getPhoneVCode = (param) => {
  return POST(API.api_getMessage, param);
};

// 获取邮箱验证码
export const getEmailVCode = (param) => {
  return POST(API.api_getEmailMessage, param);
};

// 重置密码
export const setPassword = (param) => {
  return POST(API.api_setPassword, param);
};

// 用户注册
export const userRegister = (param) => {
  return POST(API.api_userRegister, param);
};

// 获取个人信息
export const getUserInfo = () => {
  return POST(API.api_getUserInfo)
}
// 修改昵称
export const bindUserName = (parameters) => {
  return POST(API.api_bindUserName,parameters)
}
// 个人信息修改
export const userInfoEdit = (parameters) => {
  return POST(API.api_userInfoEdit, parameters)
}
// 安全设置信息
export const userSafe = () => {
  return POST(API.api_userSafe)
}
// 安全设置信息修改
export const userSafeEdit = (parameters) => {
  return POST(API.api_userSafeEdit, parameters)
}
// 绑定手机号
export const userBindPhone = (parameters) => {
  return POST(API.api_userBindPhone, parameters)
}
// 绑定邮箱
export const userBindEmail = (parameters) => {
  return POST(API.api_userBindEmail, parameters)
}
// 解绑手机号
export const userUnboundPhone = (parameters) => {
  return POST(API.api_userUnboundPhone, parameters)
}
// 解绑邮箱
export const userUnboundEmail = (parameters) => {
  return POST(API.api_userUnboundEmail, parameters)
}
// 上传头像
export const uploadUserImg = (parameters) => {
  return POST(API.api_uploadUserImg, parameters)
}
// 退出登录
export const userLoginOut = () => {
  return POST(API.api_userLoginOut)
}

// 更改邮箱或手机号之前先验证原邮箱或手机号
export const checkVCode = (parameters) => {
  return POST(API.api_checkVCode, parameters)
}

// 获取机器类型
export const getMachine = () => {
  return POST(API.api_getMachine)
}


// 获取当前本级类目列表
export const getWeaveList = (parameters) => {
  return POST(API.api_getWeaveList, parameters)
}


// 添加本级类目列表
export const addWeaveCategory = (parameters) => {
  return POST(API.api_addWeaveCategory, parameters)
}

// 编辑类目
export const editWeaveParam = (parameters) => {
  return POST(API.api_editWeaveParam, parameters)
}


// 删除类目
export const delWeaveData = (parameters) => {
  return POST(API.api_delWeaveData, parameters)
}


// 树状图数组
export const weaveCategoryTree = (parameters) => {
  return POST(API.api_weaveCategoryTree, parameters)
}



