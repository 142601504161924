
const info = {
    ZH: {  //中文翻译
        txt1: "个人中心",
        txt2: "切换机型",
        txt3: "前往针织圆机",//
        txt4: "NAO App",
        txt5: "前往NAO官网",
        txt6: "NAO公众号",
        txt7: "语言界面",
        txt8: "退出",
        txt9: "前往提花机",
    },
    EN: {  //英文翻译
        txt1: "Personal Center",
        txt2: "Switch Model",
        txt3: "Circular Knitting Machine",
        txt4: "App for iPhone",
        txt5: "NAO Home Site",
        txt6: "NAO WeChat",
        txt7: "Language Interface",
        txt8: "Exit",
        txt9: "Jacquard Loom",
    }
}
export default info  //需要暴露出去