
const info = {
    ZH: {  //中文翻译
        txt1: "数据筛选",
        txt2: "请输入",
        txt3: "请选择",
        txt4: "重置",
        txt5: "查询",
        txt6: "数据列表",
        txt7: "序号",
        txt8: "缩略图",
        txt9: "品号",
        txt10: "品名",
        // txt11: "针床类型",
        // txt12: "提花机号",
        txt11: "创建日期",
        txt12: "渲染状态",
        txt13: "操作",
        txt16: "查看",
        txt17: "更多",
        txt18: "复用",
        txt19: "导出",
        txt20: "删除",
        txt21: "选择导出方式",
        txt22: "导出图片",
        txt23: "导出PDF",
        txt24: "上机工艺文件",
        txt25: "提交",
        txt26: "取消",
        txt27: "渲染中",
        txt28: "渲染成功",
        txt29: "渲染失败",
        txt30: "编辑成功",
        txt31: "保存",
        txt32: "工艺要求",//
        txt33: "",
        txt34: "成品工艺说明：",
        txt35: "",
        txt36: "",
        txt37: "",
        txt38: "",
        txt39: "",

        txt40:"罗口宽",
        txt41:"罗口高",
        txt42:"上筒长",
        txt43:"横拉宽",
        txt44:"下筒长",
        txt45:"横拉宽",

        txt46:"最高水温30度",
        txt47:"30度温和洗涤",
        txt48:"30度非常温和洗涤",
        txt49:"最高水温40度",
        txt50:"40度温和洗涤",
        txt51:"40度非常温和洗涤",
        txt52:"最高水温50度",
        txt53:"50度温和洗涤",
        txt54:"50度非常温和洗涤",
        txt55:"可手洗",
        txt56:"禁止水洗",

        txt57:"碳氢化合物溶剂",
        txt58:"碳氢化合物溶剂温和洗涤",
        txt59:"四氯乙烯溶剂",
        txt60:"四氯乙烯溶剂温和洗涤",
        txt61:"可干洗",

        txt62:"可烘干",
        txt63:"低温烘干",
        txt64:"常温烘干",
        txt65:"不可烘干",

        txt66:"可漂白",
        txt67:"不可漂白",

        txt68:"可熨烫",
        txt69:"低温熨烫",
        txt70:"中温熨烫",
        txt71:"高温熨烫",
        txt72:"不可熨烫",
        txt73:"垫布熨烫",

        txt74:"悬挂滴干",
        txt75:"悬挂晾干",
        txt76:"平摊滴干",
        txt77:"平摊晾干",
        txt78:"阴凉处悬挂滴干",
        txt79:"阴凉处悬挂晾干",
        txt80:"阴凉处平摊滴干",
        txt81:"阴凉处平摊晾干",

        txt90:"洗涤标准",
        txt91:"请输入品号",
        txt92:"请输入品名",
        txt93:"单针床",
        txt94:"上一页",
        txt95:"下一页",
       
    },
    EN: {  //英文翻译
        txt1: "Data Filtering",
        txt2: 'Please enter',
        txt3: "Please select",
        txt4: "Reset",
        txt5: "Search",
        txt6: "Data List",
        txt7: "Serial No.",
        txt8: "Thumbnail",
        txt9: "Product No.",
        txt10: "Product Name",
        txt11: "Date",
        txt12: "Jacquard Loom No.",
        txt13: "Date",
        txt14: "Rendering Status",
        txt15: "Operation",
        txt16: "Check",
        txt17: "More",
        txt18: "Reuse",
        txt19: "Export",
        txt20: "Delete",
        txt21: "Select export method",
        txt22: "Export pictures",
        txt23: "Export PDF",
        txt24: "On machine process documents",
        txt25: "Submit",
        txt26: "Cancel",
        txt27: "Ongoing",
        txt28: "Finished",
        txt29: "Failed",
        txt30: "Edit successful",
        txt31: "Save",
        txt32: "Process Requirement",
        txt33: "Weight",
        txt34: "Finished Product Process Info：",
        txt35: "",
        txt36: "",
        txt37: "",
        txt38: "",
        txt39: "",

        txt40:"Socks Cuff Width",
        txt41:"Socks Cuff Height",
        txt42:"Socks Leg Length",
        txt43:"Socks Leg Width",
        txt44:"Socks Foot Length",
        txt45:"Socks Foot Width",

        txt46:"Maximum water temperature 30 degrees",
        txt47:"Gentle wash at 30 degrees",
        txt48:"Very gentle wash at 30 degrees",
        txt49:"Maximum water temperature 40 degrees",
        txt50:"Gentle wash at 40 degrees",
        txt51:"Very gentle wash at 40 degrees",
        txt52:"Maximum water temperature 50 degrees",
        txt53:"Gentle wash at 50 degrees",
        txt54:"Very gentle wash at 50 degrees",
        txt55:"Hand washable",
        txt56:"No washing allowed",
        
        txt57:"Hydrocarbon solvent",
        txt58:"Hydrocarbon solvent gentle cleaning",
        txt59:"Tetrachlorethylene solvent",
        txt60:"Perchlorethylene solvent gentle cleaning",
        txt61:"Dry cleanable",
        
        txt62:"Can be tumble dried",
        txt63:"Low temperature drying",
        txt64:"Dry at room temperature",
        txt65:"Do not tumble dry",

        txt66:"Bleachable",
        txt67:"Do not bleach",
        
        txt68:"Ironable",
        txt69:"Low temperature ironing",
        txt70:"Temperature ironing",
        txt71:"High temperature ironing",
        txt72:"Do not iron",
        txt73:"Doily ironing",

        txt74:"Hang drip dry",
        txt75:"Hang to dry",
        txt76:"Spread flat and drip dry",
        txt77:"Spread flat to dry",
        txt78:"Hang to drip dry in a cool place",
        txt79:"Hang to dry in a cool place",
        txt80:"Spread flat and drip dry in a cool place",
        txt81:"Spread flat in a cool place to dry",
        

        txt90:"Washing Standard",
        txt91:"Please enter item number",
        txt92:"Please enter product name",
        txt93:"Single Needle Bed",
        txt94:"Previous Page",
        txt95:"Next Page",
    }
}
export default info  //需要暴露出去