
const info = {
    ZH: {  //中文翻译
        txt1: "详情页",
        txt2: "复用",
        txt3: "导出",
        txt4: "删除",
        txt5: "(2) 纱线信息",
        txt6: "序号",
        txt7: "纱线颜色",  //
        txt8: "色号",
        txt9: "HEX",
        txt10: "原料",
        txt11: "纱支",
        txt12: "(1) 面料信息",
        txt13: "品号",
        txt14: "品名",
        txt15: "工艺要求",
        txt16: "罗口宽",
        txt17: "罗口高",
        txt18: "上筒长",
        txt19: "横拉宽",
        txt20: "下筒长",
        txt21: "横拉宽",
        txt22: "创建日期",
        txt23: "(3) 织物效果",
        txt24: "3D渲染图",
        txt25: "全屏展示",
        txt26: "2D渲染图",
        txt27: "(4) 结构参数",
        txt28: "(5) 成品要求",
        txt29: "水洗标准",
        txt30: "干洗标准",
        txt31: "烘干标准",
        txt32: "晾干标准",
        txt33: "漂白标准",
        txt34: "熨烫标准",
        txt35: "退出",
        txt36: "正面",
        txt37: "反面",
        txt38: "选择导出格式",
        txt39: "JPG图片",
        txt40: "PDF文件",
        txt41: "BPM上机工艺文件",
        txt42: "确认",
        txt43: "取消",
        txt44: "织物工艺详情",
        txt45: "效果图",
        txt46: "复用布料信息",
        txt47: "确认返回织布页面并复用此织布信息",
        txt48: "删除织布记录",
        txt49: "确认删除该记录",
        txt50: "删除成功，即将刷新",
        txt51: "导出失败，文件不存在",
        txt52: "参数信息",
        txt53: "保存",
        txt54: "提交成功！",
        txt55: "继续织布",
        txt56: "织布列表",
        txt57: "100%棉",
        txt58: "花型意匠图",
        txt59: "结构版单",
        txt60: "模型",
        txt61: "背景",
        txt62: "单只",
        txt63: "一双",
        txt64: "暗",
        txt65: "明",
        txt66: "袜身",
        txt67: "罗口",
        txt68: "袜头",
        txt69: "袜跟",
        txt70: "本次设计未上传结构版单～",
    },
    EN: {  //英文翻译
        txt1: "Detail Page",
        txt2: "Reuse",
        txt3: "Export",
        txt4: "Delete",
        txt5: "(2) Yarn Info",
        txt6: "Serieal No.",
        txt7: "Color",
        txt8: "Color Code",
        txt9: "HEX",
        txt10: "Component",
        txt11: "Yarn Count",
        txt12: "(1) Fabric Info",
        txt13: "Product No.",
        txt14: "Product Name",
        txt15: "Process Requirement",
        txt16: "Socks Cuff Width",
        txt17: "Socks Cuff Height",
        txt18: "Socks Leg Length",
        txt19: "Socks Leg Width",
        txt20: "Socks Foot Length",
        txt21: "Socks Foot Width",
        txt22: "Date",
        txt23: "(3) Textile Demonstration",
        txt24: "3D Result",
        txt25: "Full Screen",
        txt26: "2D Result",
        txt27: "(4) Structure Parameters",
        txt28: "(5) Finished Product Warning",
        txt29: "Finished Product Warning",
        txt30: "Dry Cleaning Standard",
        txt31: "Tumble Drying Standard",
        txt32: "Air Drying Standard",
        txt33: "Bleaching Standard",
        txt34: "Ironing Standard",
        txt35: "Exit",
        txt36: "Front",
        txt37: "Back",
        txt38: "Select Export Format",
        txt39: "JPG",
        txt40: "PDF",
        txt41: "BPM",
        txt42: "confirm",
        txt43: "Cancel",
        txt44: "Fabric Crafting Details",
        txt45: "Illustration",
        txt46: "Reuse cloth information",
        txt47: "Confirm to return to the weaving page and reuse this weaving information.",
        txt48: "Delete weaving records",
        txt49: "Confirm to delete this record",
        txt50: "Successfully deleted, about to refresh",
        txt51: "Export failed, file does not exist",
        txt52: "Parameter Information",
        txt53: "Save",
        txt54: "Submitted successfully",
        txt55: "Continue knitting",
        txt56: "Fabric list",
        txt57: "100% cotton",
        txt58: "Floral Pattern Design",
        txt59: "Construction Diagram",
        txt60: "Model",
        txt61: "Background",
        txt62: "Single",
        txt63: "A pair",
        txt64: "Dark",
        txt65: "Light",
        txt66: "Socks Body",
        txt67: "Socks Cuff",
        txt68: "Socks Toe",
        txt69: "Heel",
        txt70: "The design structure sheet has not been uploaded for this design～",
    }
}
export default info  //需要暴露出去