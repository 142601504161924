<template>
  <div class="ClassTreePage">
    <div class="ClassTreePagemask"></div>
    <div class="con">
      <div class="conTop">
        <span></span>
        选择类目
        <img src="@/images/hzHidden.png" alt="" @click="closeClassTree" />
      </div>
      <div class="treeBox">
        <div class="SelectedTxt">
          已选择: <span>{{ SelectedName }}</span>
        </div>
        <div class="preBox">
          <div class="tree marginTop">
            <!-- 一级类目 -->
            <div
              class="box"
              :style="index == tablelist.length - 1 ? 'min-height: 0;' : ''"
              v-for="(item, index) in tablelist"
              :key="index"
            >
              <!-- 一级类目内容 -->
              <div class="label">
                <img
                  src="@/images/openBtn.png"
                  alt=""
                  v-show="!item.type"
                  class="typeImg"
                  @click="setListType(1, index)"
                />
                <img
                  src="@/images/closeBtn.png"
                  alt=""
                  v-show="item.type"
                  class="typeImg"
                  @click="setListType(1, index)"
                />
                <div class="border"></div>
                <div class="checkType">
                  <div class="checkBox" v-show="item.type"></div>
                </div>
                <div class="nameTxt">{{ item.categoryName }}</div>
              </div>
              <!-- 二级类目 -->
              <div
                v-show="item.type && item.son.length != 0"
                class="marginLeft"
              >
                <div class="tree">
                  <div
                    class="box"
                    v-for="(items, indexs) in item.son"
                    :key="indexs"
                    :style="
                      indexs == item.son.length - 1 ? 'min-height: 0;' : ''
                    "
                  >
                    <!-- 二级类目内容 -->
                    <div class="label">
                      <img
                        src="@/images/openBtn.png"
                        alt=""
                        v-show="!items.type"
                        class="typeImg"
                        @click="setListType(2, index, indexs)"
                      />
                      <img
                        src="@/images/closeBtn.png"
                        alt=""
                        v-show="items.type"
                        class="typeImg"
                        @click="setListType(2, index, indexs)"
                      />
                      <div class="border"></div>
                      <div class="checkType">
                        <div class="checkBox" v-show="items.type"></div>
                      </div>
                      <div class="nameTxt">{{ items.categoryName }}</div>
                    </div>

                    <!-- 三级类目 -->
                    <div
                      v-show="items.type && items.son.length != 0"
                      class="marginLeft"
                    >
                      <div class="tree">
                        <div
                          class="box"
                          v-for="(Level3items, Level3Index) in items.son"
                          :key="Level3Index"
                          :style="
                            Level3Index == items.son.length - 1
                              ? 'min-height: 0;'
                              : ''
                          "
                        >
                          <!-- 三级类目内容 -->
                          <div class="label">
                            <img
                              src="@/images/openBtn.png"
                              alt=""
                              v-show="!Level3items.type"
                              class="typeImg"
                              @click="
                                setListType(3, index, indexs, Level3Index)
                              "
                            />
                            <img
                              src="@/images/closeBtn.png"
                              alt=""
                              v-show="Level3items.type"
                              class="typeImg"
                              @click="
                                setListType(3, index, indexs, Level3Index)
                              "
                            />
                            <div class="border"></div>
                            <div class="checkType">
                              <div
                                class="checkBox"
                                v-show="Level3items.type"
                              ></div>
                            </div>
                            <div class="nameTxt">
                              {{ Level3items.categoryName }}
                            </div>
                          </div>

                          <!-- 四级类目 -->
                          <div
                            v-show="
                              Level3items.type && Level3items.son.length != 0
                            "
                            class="marginLeft"
                          >
                            <div class="tree">
                              <div
                                class="box"
                                v-for="(
                                  Level4items, Level4Index
                                ) in Level3items.son"
                                :key="Level4Index"
                                :style="
                                  Level4Index == Level3items.son.length - 1
                                    ? 'min-height: 0;'
                                    : ''
                                "
                              >
                                <!-- 四级类目内容 -->
                                <div class="label">
                                  <img
                                    src="@/images/openBtn.png"
                                    alt=""
                                    v-show="!Level4items.type"
                                    class="typeImg"
                                    @click="
                                      setListType(
                                        4,
                                        index,
                                        indexs,
                                        Level3Index,
                                        Level4Index
                                      )
                                    "
                                  />
                                  <img
                                    src="@/images/closeBtn.png"
                                    alt=""
                                    v-show="Level4items.type"
                                    class="typeImg"
                                    @click="
                                      setListType(
                                        4,
                                        index,
                                        indexs,
                                        Level3Index,
                                        Level4Index
                                      )
                                    "
                                  />
                                  <div class="border"></div>
                                  <div class="checkType">
                                    <div
                                      class="checkBox"
                                      v-show="Level4items.type"
                                    ></div>
                                  </div>
                                  <div class="nameTxt">
                                    {{ Level4items.categoryName }}
                                  </div>
                                </div>
                                <!-- 五级类目 -->
                                <div
                                  v-show="
                                    Level4items.type &&
                                    Level4items.son.length != 0
                                  "
                                  class="marginLeft"
                                >
                                  <div class="tree">
                                    <div
                                      class="box"
                                      v-for="(
                                        Level5items, Level5Index
                                      ) in Level4items.son"
                                      :key="Level5Index"
                                      :style="
                                        Level5Index ==
                                        Level4items.son.length - 1
                                          ? 'min-height: 0;margin-left:0'
                                          : 'margin-left:0'
                                      "
                                    >
                                      <!-- 五级类目内容 -->
                                      <div class="label">
                                        <div
                                          class="border bordermarginLeft"
                                        ></div>
                                        <div
                                          class="checkType"
                                          v-show="!Level5items.type"
                                          style="cursor: pointer"
                                          @click="
                                            setListType(
                                              5,
                                              index,
                                              indexs,
                                              Level3Index,
                                              Level4Index,
                                              Level5Index
                                            )
                                          "
                                        ></div>
                                        <img
                                          src="@/images/Classcheckimg.png"
                                          class="Classcheckimg"
                                          alt=""
                                          @click="
                                            setListType(
                                              5,
                                              index,
                                              indexs,
                                              Level3Index,
                                              Level4Index,
                                              Level5Index
                                            )
                                          "
                                          v-show="Level5items.type"
                                        />
                                        <div class="nameTxt">
                                          {{ Level5items.categoryName }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="qrbtn" @click="sendData">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import { weaveCategoryTree, getWeaveList } from "@/api/user/user";
export default {
  data() {
    return {
      SelectedName: "",
      tablelist: [],
      copyTablelist: [],
      indexArr: [1000, 1000, 1000, 1000, 1000],
      SelectedNameType: 1,
      checkArr: [],
      setpNumber: 0,
    };
  },
  mounted() {
    this.tablelist = [];
    weaveCategoryTree().then((res) => {
      if (res.code == 0) {
        res.data.forEach((item, index) => {
          // 一级
          item.type = false;
          this.tablelist.push(item);
          if (item.son != undefined) {
            // 二级
            item.son.forEach((item2, index2) => {
              this.$set(this.tablelist[index].son[index2], "type", false);
              if (item2.son != undefined) {
                // 三级
                item2.son.forEach((item3, index3) => {
                  this.$set(
                    this.tablelist[index].son[index2].son[index3],
                    "type",
                    false
                  );
                  if (item3.son != undefined) {
                    // 四级
                    item3.son.forEach((item4, index4) => {
                      this.$set(
                        this.tablelist[index].son[index2].son[index3].son[
                          index4
                        ],
                        "type",
                        false
                      );
                      if (item4.son != undefined) {
                        //五级
                        item4.son.forEach((item5, index5) => {
                          this.$set(
                            this.tablelist[index].son[index2].son[index3].son[
                              index4
                            ].son[index5],
                            "type",
                            false
                          );
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
        this.copyTablelist = JSON.parse(JSON.stringify(this.tablelist));
      }
    });
  },
  methods: {
    //展开或关闭树状图
    setListType(type, index, indexs, Level3Index, Level4Index, Level5Index) {
      switch (type) {
        case 1:
          //一级
          if (this.tablelist[index].son == undefined) {
            return;
          }
          this.SelectedName = "";
          if (this.indexArr[0] == 1000) {
            this.$set(this.indexArr, 0, index);
          } else {
            if (this.indexArr[0] != index) {
              this.tablelist = JSON.parse(JSON.stringify(this.copyTablelist));
              this.$set(this.indexArr, 0, index);
            }
          }
          this.$set(this.tablelist[index], "type", !this.tablelist[index].type);
          this.SelectedNameType = 1;
          this.setName(this.tablelist, 1);
          break;
        case 2:
          if (this.tablelist[index].son[indexs].son == undefined) {
            return;
          }
          this.SelectedName = "";
          if (this.indexArr[1] == 1000) {
            this.$set(this.indexArr, 1, indexs);
          } else {
            if (this.indexArr[1] != indexs) {
              this.tablelist[index].son = JSON.parse(
                JSON.stringify(this.copyTablelist[index].son)
              );
              this.$set(this.indexArr, 1, indexs);
            }
          }
          this.$set(
            this.tablelist[index].son[indexs],
            "type",
            !this.tablelist[index].son[indexs].type
          );
          this.SelectedNameType = 1;
          this.setName(this.tablelist);
          break;
        case 3:
          if (
            this.tablelist[index].son[indexs].son[Level3Index].son == undefined
          ) {
            return;
          }
          this.SelectedName = "";

          if (this.indexArr[2] == 1000) {
            this.$set(this.indexArr, 2, indexs);
          } else {
            if (this.indexArr[2] != Level3Index) {
              this.tablelist[index].son[indexs].son = JSON.parse(
                JSON.stringify(this.copyTablelist[index].son[indexs].son)
              );
              this.$set(this.indexArr, 2, Level3Index);
            }
          }
          this.$set(
            this.tablelist[index].son[indexs].son[Level3Index],
            "type",
            !this.tablelist[index].son[indexs].son[Level3Index].type
          );
          this.SelectedNameType = 1;
          this.setName(this.tablelist);
          break;
        case 4:
          if (
            this.tablelist[index].son[indexs].son[Level3Index].son[Level4Index]
              .son == undefined
          ) {
            return;
          }
          this.SelectedName = "";
          if (this.indexArr[3] == 1000) {
            this.$set(this.indexArr, 3, Level4Index);
          } else {
            if (this.indexArr[3] != Level4Index) {
              this.tablelist[index].son[indexs].son[Level4Index].son =
                JSON.parse(
                  JSON.stringify(
                    this.copyTablelist[index].son[indexs].son[Level4Index].son
                  )
                );
              this.$set(this.indexArr, 3, Level4Index);
            }
          }
          this.$set(
            this.tablelist[index].son[indexs].son[Level3Index].son[Level4Index],
            "type",
            !this.tablelist[index].son[indexs].son[Level3Index].son[Level4Index]
              .type
          );
          this.SelectedNameType = 1;
          this.setName(this.tablelist);
          break;
        case 5:
          this.SelectedName = "";

          this.tablelist[index].son[indexs].son[Level3Index].son[
            Level4Index
          ].son.forEach((el, i) => {
            if (i == Level5Index && el.type) {
            } else {
              this.$set(
                this.tablelist[index].son[indexs].son[Level3Index].son[
                  Level4Index
                ].son[i],
                "type",
                false
              );
            }
          });

          this.$set(
            this.tablelist[index].son[indexs].son[Level3Index].son[Level4Index]
              .son[Level5Index],
            "type",
            !this.tablelist[index].son[indexs].son[Level3Index].son[Level4Index]
              .son[Level5Index].type
          );
          this.SelectedNameType = 1;
          this.setName(this.tablelist);
          break;
      }
    },

    //传递选择类目
    sendData() {
      this.setpNumber = 0;
      this.getname(this.tablelist);
    },

    getname(arr) {
      this.setpNumber += 1;
      let num = 0;
      arr.forEach((item, index) => {
        if (item.type) {
          if (item.son != undefined) {
            if (item.son.length != 0) {
              this.getname(item.son);
            } else {
              this.$emit("sendName", "");
            }
          } else {
            if (this.setpNumber == 5) {
              this.$emit("sendName", item.categoryName);
            } else {
              this.$emit("sendName", "");
            }
          }
        } else {
          num++;
          if (index == arr.length - 1) {
            if (num == arr.length) {
              this.$emit("sendName", "");
            }
          }
        }
      });
    },

    //拼接面包屑
    setName(arr) {
      let list = JSON.parse(JSON.stringify(arr));
      list.forEach((item, index) => {
        if (item.type) {
          if (this.SelectedNameType == 1) {
            this.SelectedNameType = 2;
            if (item.categoryName.length > 5) {
              item.categoryName = item.categoryName.slice(0, 5) + "...";
            }
            this.SelectedName += item.categoryName;
          } else {
            if (item.categoryName.length > 5) {
              item.categoryName = item.categoryName.slice(0, 5) + "...";
            }
            this.SelectedName += "—" + item.categoryName;
          }
          if (item.son != undefined) {
            this.setName(item.son);
          }
        }
      });
    },

    // 关闭选择类目弹框
    closeClassTree() {
      this.$emit("closeClassTree");
    },

    // 传递类目名称
    sendCategoryName(name) {
      if (name != "") {
        this.tablelist = JSON.parse(JSON.stringify(this.copyTablelist));
        getWeaveList({
          categoryName: name,
          pid: "search",
        }).then((res) => {
          this.SelectedName = "";
          this.checkArr = res.data.parents;
          this.checkArr.push(res.data.self);
          if (this.checkArr.length != 0) {
            this.getcheckArr(this.tablelist);
          }
        });
      } else {
        this.tablelist = JSON.parse(JSON.stringify(this.copyTablelist));
        this.SelectedName = "";
      }
    },

    getcheckArr(arr) {
      arr.forEach((item, index) => {
        //一级
        if (item.id == this.checkArr[0].id) {
          this.$set(this.tablelist[index], "type", true);
          this.SelectedName += item.categoryName;
          //二级
          item.son.forEach((item2, index2) => {
            if (item2.id == this.checkArr[1].id) {
              this.$set(this.tablelist[index].son[index2], "type", true);
              this.SelectedName += "—" + item2.categoryName;

              //三级
              item2.son.forEach((item3, index3) => {
                if (item3.id == this.checkArr[2].id) {
                  this.SelectedName += "—" + item3.categoryName;
                  this.$set(
                    this.tablelist[index].son[index2].son[index3],
                    "type",
                    true
                  );

                  //四级
                  item3.son.forEach((item4, index4) => {
                    if (item4.id == this.checkArr[3].id) {
                      this.SelectedName += "—" + item4.categoryName;
                      this.$set(
                        this.tablelist[index].son[index2].son[index3].son[
                          index4
                        ],
                        "type",
                        true
                      );

                      //五级
                      item4.son.forEach((item5, index5) => {
                        if (item5.id == this.checkArr[4].id) {
                          this.SelectedName += "—" + item5.categoryName;
                          this.$set(
                            this.tablelist[index].son[index2].son[index3].son[
                              index4
                            ].son[index5],
                            "type",
                            true
                          );
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./ClassTree.less");
</style>