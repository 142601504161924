
import index from "./data/index"  //用户模块翻译文件引入

const zh = {
    language: {
        name: '中文'
    },
    login: index.Login.ZH,
    modelSwitching: index.modelSwitching.ZH,
    machine: index.machine.ZH,
    register: index.User.ZH,
    left: index.left.ZH,
    renderResults: index.renderResults.ZH,
    User: index.User.ZH,
    pup: index.pup.ZH,
    historyList: index.historyList.ZH,
    historyDetail: index.historyDetail.ZH,
    downloadPage: index.downloadPage.ZH,
    head: index.head.ZH,
}
export default zh  //暴露出去