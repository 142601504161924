<template>
  <div>
    <el-popover
      placement="bottom-start"
      trigger="click"
      popper-class="selectMachine"
      ref="popover"
      v-model="visible"
      @show="showPup"
    >
      <ul class="machineList">
        <li
          v-for="(item, index) in machineList"
          :key="item.machineName"
          @click="handleCommand(item, index)"
          :style="{
            background: currentCommand === item.type ? '#E6F7FF' : '',
          }"
        >
          {{ item.machineName }}
        </li>
      </ul>
      <div slot="reference" style="cursor: pointer">{{ machineData }}</div>
    </el-popover>
    <CommonDialog
      :dialogVisible="isShowDialog"
      :content="setContent"
      :confirmContent="setConfirmContent"
      @cancelDialog="cancelDialog"
      @confirmDialog="confirmDialog"
    ></CommonDialog>
  </div>
</template>
<script>
import CommonDialog from "../commonDialog/CommonDialog.vue";
import { getMachine } from "@/api/user/user";
export default {
  name: "MachineSelect",
  data() {
    return {
      // machineData: "",
      // machineData: "普通双面(2+4)大圆机",
      machineData: "单面大圆机",
      visible: false,
      /* machineType: [
        { label: "oneSide", value: "单面大圆机" },
        { label: "twoSide", value: "普通双面(2+2)大圆机" },
        { label: "twoFourSide", value: "双面(2+4)大圆机" },
        { label: "fourSide", value: "普通双面(4+4)大圆机" },
      ], */
      // showMachineSelectInput: true,
      setContent: "切换设备",
      setConfirmContent: "数据将会清空，是否继续",
      isShowDialog: false,
      currentMachine: {},
      circularMachineType: {
        1: [2, 2],
        2: [2, 4],
        3: [4, 4],
      },
      currentCommand: 0,
      machineList: JSON.parse(localStorage.getItem("machineList")),
      machineIndex: "",
    };
  },
  components: {
    CommonDialog,
  },
  methods: {
    showPup() {},
    handleCommand(currentMachine, index) {
      this.visible = false;
      this.currentMachine = currentMachine;
      this.machineIndex = index;
      let count = 0;
      this.$store.state.index.clearType.forEach((item, index) => {
        if (item == 1) {
          count++;
        }
      });
      if (count > 0) {
        this.isShowDialog = true;
      } else {
        this.changeMachine(this.machineIndex);
      }
    },
    changeMachine(index) {
      const { currentMachine } = this;
      this.machineData = currentMachine.machineName;
      this.currentCommand = currentMachine.type;
      localStorage.setItem("clearType", 1);
      localStorage.setItem("machineType", JSON.stringify(currentMachine));

      if (index < 4) {
        this.$router.push({
          path: "/index",
          query: {
            machineType: index,
            clearType: 1,
          },
        });
      } else {
        if (index == 4) {
          this.$router.push({
            path: "/OnejacquardWeave?pageType=" + index,
          });
        } else if (index == 5) {
          this.$router.push({
            path: "/DoublejacquardWeave?pageType=" + index,
          });
        } else {
          this.$router.push({
            path: "/IntegratedMachineIndex?pageType=" + index,
          });
        }
      }
    },
    // 确认切换机型
    confirmDialog() {
      this.changeMachine(this.machineIndex);
      this.isShowDialog = false;
    },
    // 取消切换机型
    cancelDialog() {
      this.isShowDialog = false;
    },
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        if (!this.machineList) {
          this.machineList = JSON.parse(localStorage.getItem("machineList"));
        }
        if (newVal.path == "/" && oldVal == undefined) {
        }

        if (newVal.path.includes("/index") || newVal.path == "/") {
          if (
            newVal.query.machineType == 0 ||
            (newVal.path == "/" && oldVal != undefined)
          ) {
            this.machineData = this.machineList[0].machineName;
            this.currentCommand = this.machineList[0].type;
            localStorage.setItem(
              "machineType",
              JSON.stringify(this.machineList[0])
            );
          }
          if (newVal.query.machineType == 1) {
            this.machineData = this.machineList[1].machineName;
            this.currentCommand = this.machineList[1].type;
            localStorage.setItem(
              "machineType",
              JSON.stringify(this.machineList[1])
            );
          }
          if (newVal.query.machineType == 2) {
            this.machineData = this.machineList[2].machineName;
            this.currentCommand = this.machineList[2].type;
            localStorage.setItem(
              "machineType",
              JSON.stringify(this.machineList[2])
            );
          }
          if (newVal.query.machineType == 3) {
            this.machineData = this.machineList[3].machineName;
            this.currentCommand = this.machineList[3].type;
            localStorage.setItem(
              "machineType",
              JSON.stringify(this.machineList[3])
            );
          }
        }
        if (newVal.path.includes("/OnejacquardWeave")) {
          this.machineData = this.machineList[4].machineName;
          this.currentCommand = this.machineList[4].type;
          localStorage.setItem(
            "machineType",
            JSON.stringify(this.machineList[4])
          );
        }
        if (newVal.path.includes("/DoublejacquardWeave")) {
          this.machineData = this.machineList[5].machineName;
          this.currentCommand = this.machineList[5].type;
          localStorage.setItem(
            "machineType",
            JSON.stringify(this.machineList[5])
          );
        }
        if (newVal.path.includes("/IntegratedMachineIndex")) {
          this.machineData = this.machineList[6].machineName;
          this.currentCommand = this.machineList[6].type;
          localStorage.setItem(
            "machineType",
            JSON.stringify(this.machineList[6])
          );
        }
        /* if (oldVal != undefined && oldVal.name == "loginUp") {
          this.machineList = JSON.parse(localStorage.getItem("machineList"));
          localStorage.setItem(
            "machineType",
            JSON.stringify(this.machineList[0])
          );
        } */
        // let macType = JSON.parse(localStorage.getItem("machineType"));
        // this.machineData = macType.machineName;
        // this.currentMachine = macType;
        // this.currentCommand = macType.type;

        if (
          oldVal != undefined &&
          oldVal.path.includes("/renderResults") &&
          newVal.path.includes("/historyDetail") &&
          JSON.parse(localStorage.getItem("clearType")) == 0
        ) {
          localStorage.setItem("clearType", 1);
        } else if (
          oldVal != undefined &&
          (oldVal.path.includes("/historyDetail") ||
            oldVal.path.includes("/downloadpage")) &&
          JSON.parse(localStorage.getItem("clearType")) == 1
        ) {
          localStorage.setItem("clearType", 1);
        } else if (
          newVal.path.includes("/index") &&
          JSON.parse(localStorage.getItem("clearType")) == 1
        ) {
          localStorage.setItem("clearType", 1);
        } else if (
          // OnejacquardWeave DoublejacquardWeave
          (newVal.path.includes("/OnejacquardWeave") ||
            newVal.path.includes("/DoublejacquardWeave")) &&
          JSON.parse(localStorage.getItem("clearType")) == 1
        ) {
          localStorage.setItem("clearType", 1);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  mounted() {
  },
};
</script>
<style >
.selectMachine {
  padding: 0px !important;
  z-index: 100 !important;
}
</style>
<style lang="less" scoped>
@import "./MachineSelect.less";
</style>