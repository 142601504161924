<template>
  <div id="app" class="page">
    <HeaderNav v-if="$route.meta.tabbar && $route.meta.headerType != 1" />
    <LeftNav v-show="$route.meta.tabbar" />
    <keep-alive>
      <router-view
        class="pageCon"
        :style="!$route.meta.tabbar ? 'padding:0' : ''"
        v-if="$route.meta.keepAlive"
      ></router-view>
    </keep-alive>
    <router-view
      v-if="$route.meta.keepAlive == undefined ? true : false"
      class="pageCon"
      :style="!$route.meta.tabbar ? 'padding:0' : ''"
    ></router-view>
  </div>
</template>

<script>
import HeaderNav from "./components/headerNav/headerNav.vue";
import LeftNav from "./components/leftNav/leftNav.vue";
import ClassTree from "./components/ClassTree/ClassTree.vue";

export default {
  name: "App",
  components: {
    HeaderNav,
    LeftNav,
    ClassTree,
  },
  data() {
    return {};
  },
  // 绑定窗口关闭[监听]事件
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
  },
  // 解除窗口关闭[监听]事件
  destroyed() {
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
  },
  methods: {
    beforeunloadHandler(e) {
      console.log("关闭窗口之后");
      localStorage.removeItem("postConsolidationData");
    },
  },
};
</script>
  
<style lang="less">
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
.page {
  width: 100vw;
  height: 100vh;
  background: #f3f5f5;
  position: relative;

  .pageCon {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 64px 0 0 90px;
    overflow: overlay;
  }

  .pageCon::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 6px;
    cursor: pointer;
  }

  /* 滚动槽 */
  .pageCon::-webkit-scrollbar-track {
    border-radius: 6px;
    cursor: pointer;
  }

  /* 滚动条滑块 */
  .pageCon::-webkit-scrollbar-thumb {
    border-radius: 4px;
    cursor: pointer;
    background: #666;
    background: rgba(0, 0, 0, 0.24);
    position: relative;
    z-index: 3;
  }

  /* 滚动条滑块 */
  .pageCon::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }
}
.checkInputItem {
  height: 47px !important;
  span {
    line-height: 47px;
  }
}
.msgBox {
  width: 436px;
  min-height: 80px;
  padding-right: 77px;
  background: #ffffff;
  border-radius: 2px;
  // border: 1px solid #d2d2d2;
  padding: 14px 0;
  box-sizing: border-box;

  .msgBoxTop {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 14px;
    box-sizing: border-box;
    img {
      margin-right: 10px;
      width: 21px;
      height: 21px;
    }
    div {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 25px;
    }
  }

  .msgTips {
    width: 314px;
    margin-top: 5px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    margin-left: 45px;
    word-wrap: break-word;
    word-break: break-all;
  }
}
.message {
  background: #444 !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 60px;
  min-width: 384px;
  border-color: #444 !important;
}

.messageBox {
  display: flex;
  align-items: center;
}

.messageImg {
  width: 28px;
  height: 28px;
}

.messageTxt {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  letter-spacing: 1px;
  margin-left: 14px;
}
// .el-message {
//   padding: 0 !important;
// }
</style>
