<template>
  <div class="headNav" :style="pageType != 0 ? 'z-index:120' : ''">
    <span></span>
    <div class="rightBox">
      <!-- 中英文切换 -->
      <div class="language">
        <span
          :style="language == 'zh' ? 'color:#101426' : ''"
          @click="handleCommand('zh')"
          >CN</span
        >
        <div></div>
        <span
          :style="language == 'en' ? 'color:#101426' : ''"
          @click="handleCommand('en')"
          >EN</span
        >
      </div>
      <!-- 导航下拉框 -->
      <el-popover
        placement="bottom"
        trigger="click"
        popper-class="headpopoverStyle"
        show="popovershow"
        v-model="popovervisible"
      >
        <div
          :class="language == 'zh' ? 'headItem' : 'headItem headItemen'"
          v-for="(item, index) in listBtn"
          :key="index"
          :style="item.borderType ? 'border: none;' : ''"
        >
          <div class="firstBox" @click="navBtn(index)">
            <img
              :src="item.icon"
              alt=""
              :class="
                language == 'zh' ? 'firstBoxImg' : 'firstBoxImg firstBoxImgen'
              "
            />
            <span
              :class="index == 1 ? 'firstBoxTxt setWidth' : 'firstBoxTxt'"
              >{{ item.txt }}</span
            >
            <img
              v-show="item.showsecond != undefined"
              src="@/newImg/subscript@2x.png"
              alt=""
              :class="
                item.showsecond ? 'subscriptImg rotateStyle' : 'subscriptImg'
              "
              @click.stop="navBtn(index)"
            />
          </div>
          <div class="secondList">
            <div
              v-show="item.showsecond"
              v-for="(items, indexs) in item.secondList"
              :key="indexs"
              class="secondListItem"
              @click="secondNavBtn(index, indexs)"
            >
              <div>{{ items.txt }}</div>
              <img v-show="items.type" src="@/newImg/checkJx@2x.png" alt="" />
            </div>
          </div>
        </div>
        <div class="userBox" @click="popovershow" slot="reference">
          <img
            :src="
              this.$store.state.index.userInfo.avatar == ''
                ? require('@/newImg/avatar.png')
                : this.$store.state.index.userInfo.avatar
            "
            alt=""
          />
          <div class="userName">
            {{ this.$store.state.index.userInfo.name }}
          </div>
        </div>
      </el-popover>
    </div>
    <pupAppShow :type="pageType" @result="cancelShowAppDialog" />
    <CommonDialog
      :dialogVisible="isShowDialog"
      :content="setContent"
      :confirmContent="setConfirmContent"
      @cancelDialog="cancelDialog"
      @confirmDialog="confirmDialog"
    ></CommonDialog>
  </div>
</template>

<script>
import { userLoginOut, getUserInfo } from "@/api/user/user";
import MachineSelect from "../MachineSelect/MachineSelect.vue";
import CommonDialog from "../commonDialog/CommonDialog.vue";
import pupAppShow from "@/components/pupApp/pupAppShow"; //意匠图组件

export default {
  name: "TopNav",
  components: {
    MachineSelect,
    CommonDialog,
    pupAppShow,
  },
  data() {
    return {
      MachineType: false,
      showUserIndex: false,
      showEntry: false,
      surplusCount: 0,
      visible: this.$store.state.index.setpType,
      time1: "",
      time2: "",
      language: localStorage.getItem("languageSet"),
      popovervisible: false,
      listBtn: [
        { icon: require("@/newImg/leftNav1.png"), txt: this.$t("head.txt1") },
        /* {
          icon: require("@/newImg/leftNav2.png"),
          txt: this.$t("head.txt2"),
          showsecond: false,
          secondList: [
            { txt: this.$t("modelSwitching.txt2"), type: false },
            { txt: this.$t("modelSwitching.txt3"), type: false },
            { txt: this.$t("modelSwitching.txt4"), type: false },
            { txt: this.$t("modelSwitching.txt5"), type: false },
          ],
        }, */
        {
          icon: require("@/newImg/leftNav3.png"),
          txt: this.$t("head.txt3"),
          borderType: true,
        },
        {
          icon: require("@/newImg/leftNav3.png"),
          txt: this.$t("head.txt9"),
          borderType: true,
        },
        { icon: require("@/newImg/leftNav4.png"), txt: this.$t("head.txt4") },

        {
          icon: require("@/newImg/leftNav5.png"),
          txt: this.$t("head.txt5"),
          borderType: true,
        },
        { icon: require("@/newImg/leftNav6.png"), txt: this.$t("head.txt6") },
        {
          icon: require("@/newImg/leftNav7.png"),
          txt: this.$t("head.txt7"),
          showsecond: false,
          secondList: [
            { txt: "CN", type: false },
            { txt: "EN", type: false },
          ],
        },
        { icon: require("@/newImg/leftNav8.png"), txt: this.$t("head.txt8") },
      ],
      setContent: this.$t("left.txt10"),
      setConfirmContent: this.$t("left.txt9"),
      isShowDialog: false,
      machineListIndex: 10,
      pageType: 0,
    };
  },
  created() {
    this.language = this.$store.state.index.languageType;
  },
  mounted() {
    getUserInfo().then((res) => {
      if (res.code == 0) {
        let data = {
          avatar: res.data.avatar,
          name: res.data.nickName,
        };
        this.$store.commit("index/setuserInfo", data);
      }
    });
    setTimeout(() => {
      this.MachineType = true;
      if (this.$route.path.includes("/index") || this.$route.path == "/") {
        this.MachineType = true;
      } else {
        this.MachineType = false;
      }
    }, 100);
  },
  watch: {
    "$store.state.index.languageType"(newVal) {
      this.language = newVal;
      this.setContent = this.$t("left.txt10");
      this.setConfirmContent = this.$t("left.txt9");
      this.listBtn = [
        {
          icon: require("@/newImg/leftNav1.png"),
          txt: this.$t("head.txt1"),
        },
        {
          icon: require("@/newImg/leftNav3.png"),
          txt: this.$t("head.txt3"),
          borderType: true,
        },
        {
          icon: require("@/newImg/leftNav3.png"),
          txt: this.$t("head.txt9"),
          borderType: true,
        },
        {
          icon: require("@/newImg/leftNav4.png"),
          txt: this.$t("head.txt4"),
        },

        {
          icon: require("@/newImg/leftNav5.png"),
          txt: this.$t("head.txt5"),
          borderType: true,
        },
        {
          icon: require("@/newImg/leftNav6.png"),
          txt: this.$t("head.txt6"),
        },
        {
          icon: require("@/newImg/leftNav7.png"),
          txt: this.$t("head.txt7"),
          showsecond: false,
          secondList: [
            { txt: "CN", type: false },
            { txt: "EN", type: false },
          ],
        },
        {
          icon: require("@/newImg/leftNav8.png"),
          txt: this.$t("head.txt8"),
        },
      ];
    },
    $route: {
      handler(newVal, oldVal) {
        if (newVal.path.includes("/index") || newVal.path == "/") {
          this.MachineType = true;
        } else {
          this.MachineType = false;
        }
      },
      deep: true,
      immediate: true,
    },
    "$store.state.index.setpType"(newVal, Val) {
      if (newVal) {
        this.time1 = setTimeout(() => {
          this.visible = true;
        }, 5000);
        this.time2 = setTimeout(() => {
          this.visible = false;
        }, 6000);
      } else {
        clearTimeout(this.time1);
        clearTimeout(this.time2);
        this.visible = false;
      }
    },
  },
  methods: {
    //切换中英文
    handleCommand(command) {
      localStorage.setItem("languageSet", command);
      this.$store.commit("index/setlanguageType", command);
      this.$i18n.locale = command;
      this.language = command;
    },
    //导航功能
    navBtn(index) {
      switch (index) {
        case 0:
          this.$router.push("/userIndex");
          this.popovervisible = false;
          break;
        case 1:
          this.popovervisible = false;
          window.open("https://basic.naotextile.com");
          break;
        case 2:
          this.popovervisible = false;
          window.open("https://jacquard.naotextile.com");
          break;
        case 3:
          this.pageType = 1;
          this.popovervisible = false;
          break;
        case 4:
          this.popovervisible = false;
          window.open("https://www.naotextile.com");
          break;
        case 5:
          this.pageType = 2;
          this.popovervisible = false;
          break;
        case 6:
          this.$set(
            this.listBtn[index],
            "showsecond",
            !this.listBtn[index].showsecond
          );
          break;
        case 7:
          userLoginOut().then((res) => {
            if (res.code === 0) {
              this.popovervisible = false;
              this.$router.push("/loginUp");
              this.$store.commit("index/outLogin", 1);
              localStorage.removeItem("token");
              localStorage.removeItem("machineType");
              localStorage.removeItem("machineList");
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "warning",
              });
            }
          });
          break;
      }
    },
    //二级导航按钮
    secondNavBtn(i, j) {
      this.popovervisible = false;
      if (i == 6) {
        this.handleCommand(j == 0 ? "zh" : "en");
      }
    },
    cancelShowAppDialog() {
      this.pageType = 0;
    },
    //切换机型弹框回调(关闭)
    cancelDialog(res) {
      this.isShowDialog = false;
    },
    //切换机型弹框回调(确认)
    confirmDialog(res) {
      this.setMachineList();
    },

    //切换机型
    setMachineList() {
      localStorage.setItem("clearType", 1);
      let checkMachine = JSON.parse(localStorage.getItem("machineList"))[
        this.machineListIndex
      ];
      localStorage.setItem("machineType", JSON.stringify(checkMachine));
      this.isShowDialog = false;
      this.$router.push({
        path: "/index",
        query: {
          machineType: this.machineListIndex,
          clearType: 1,
        },
      });
    },
    popovershow() {
      // if (!this.popovervisible) {
      //   this.popovervisible = true;
      // }
      this.popovervisible = false;
      // this.$set(this.listBtn[1], "showsecond", false);
      this.$set(this.listBtn[6], "showsecond", false);
      /* this.listBtn[1].secondList.forEach((item, index) => {
        this.$set(this.listBtn[1].secondList[index], "type", false);
      });
      this.$set(
        this.listBtn[1].secondList[
          JSON.parse(localStorage.getItem("machineType")).type
        ],
        "type",
        true
      ); */
      if (this.$store.state.index.languageType == "zh") {
        this.$set(this.listBtn[6].secondList[0], "type", true);
        this.$set(this.listBtn[6].secondList[1], "type", false);
      } else {
        this.$set(this.listBtn[6].secondList[0], "type", false);
        this.$set(this.listBtn[6].secondList[1], "type", true);
      }
    },
  },
};
</script>

<style  scoped lang="less">
@import url("./headerNav.less");
</style>
<style lang="less">
.headpopoverStyle {
  padding: 0 !important;
}
.headItem {
  width: 235px;
  min-height: 48px;
  border-bottom: 1px solid #e3e4ea;

  .firstBox {
    display: flex;
    align-items: center;
    height: 48px;
    cursor: pointer;
    position: relative;

    .firstBoxImg {
      width: 15px;
      height: 15px;
      margin-right: 20px;
      margin-left: 53px;
    }

    .firstBoxImgen {
      margin-left: 30px;
    }

    .firstBoxTxt {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      letter-spacing: 1px;
    }
    .setWidth {
      width: 129px;
    }

    .subscriptImg {
      width: 13px;
      height: 9px;
      position: absolute;
      right: 20px;
      transition: all 0.3s;
    }

    .rotateStyle {
      transform: rotate(180deg);
    }
  }
  .secondList {
    width: 100%;
    background: #f1f2f4;
    box-shadow: inset 0px 0px 4px 0px rgba(204, 204, 204, 0.5);

    .secondListItem {
      width: 100%;
      height: 48px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 48px;
      letter-spacing: 1px;
      border-bottom: 1px solid #e3e4ea;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 0 20px;
      position: relative;
      img {
        width: 14px;
        height: 9px;
        position: absolute;
        right: 20px;
      }
    }

    .secondListItem:last-child {
      border: none;
    }
  }
}

.headItemen {
  width: 253px;
}
</style>