// 是整个vue的入口文件

//引入Vue
import Vue from "vue";

//引入store文件
import store from "./store/index";

//引入App.vue组件,是所有组件的顶级
import App from "./App.vue";

//引入router
import VueRouter from "vue-router";
Vue.use(VueRouter);
import { message } from "./utils/message";
Vue.prototype.$messagePup = message

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

import GeminiScrollbar from "vue-gemini-scrollbar";

Vue.use(GeminiScrollbar);

import "./icons/index";

import "./utils/rem";

import resetMessage from './utils/resetMessage';

// //视频播放器
import VueVideoPlayer from 'vue-video-player'
// require videojs style
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'

Vue.use(VueVideoPlayer)

//引入el
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
ElementUI.Dialog.props.lockScroll.default = false; // 打开弹窗防止页面抖动
Vue.prototype.$message = resetMessage
import router from "./router/router";
// main.js
Vue.use(ElementUI)
import ZH from '@/utils/language/zh.js'  //中文最终汇总暴露的信息
import EN from '@/utils/language/en.js'  //英文
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem('languageSet') || 'zh',   //从localStorage里获取用户中英文选择，没有则默认中文
  messages: {
    'zh': ZH,
    'en': EN
  }
})


import './utils/msgStyle'

//关闭vue的生产提升
Vue.config.productionTip = false;
//创建vue实例化
new Vue({
  el: "#app", //挂载容器
  store,
  router,
  i18n,
  render: (h) => h(App), //将app组件放到容器中
});
