
import index from "./data/index"  //用户模块翻译文件引入

const en = {
    language: {
        name: 'English'
    },
    login: index.Login.EN,
    modelSwitching: index.modelSwitching.EN,
    machine: index.machine.EN,
    register: index.User.EN,
    left:index.left.EN,
    renderResults:index.renderResults.EN,
    User: index.User.EN,
    pup: index.pup.EN,
    historyList: index.historyList.EN,
    historyDetail: index.historyDetail.EN,
    downloadPage: index.downloadPage.EN,
    head:index.head.EN,
}
export default en  //暴露出去