/**
 * 该文件是Vuex
 */
import Vue from "vue";
import Vuex from "vuex";

import index from "./index/index";
import user from "./user/user";

//全局引入vuex
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    //引入单一vuex文件，使代码更简洁，更有模块性  ## 注意 引入外部的vuex文件时引入的文件一定要加入 namespaced:true 属性 必须为true
    index,
    user,
  },
});
