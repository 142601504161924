// * 多种通信调用
import Vue from "vue";
export const bus = new Vue();

// 获取token
export const getToken = () => {
  var token = localStorage.getItem("token");
  if (token) return token;
  else return "";
};

// 跳转登录页

export const aaa = () => {
  // this.$router.push("/loginUp");
  // Vue.$nextTick(() => {
  //   console.log(this);
  // });
  return "";
};
