
export let message = function messgaePup(contxt, Puptype) {
    this.$message({
        dangerouslyUseHTMLString: true,
        type: 'error',
        iconClass: ' ', // 这个会覆盖图标类名，为了实现去掉图标的操作
        customClass: 'message', // 这个是由于上面设置了iconClass会覆盖掉type，所以需要再设置
        message: `<div class="messageBox"><img class="messageImg" src="${require(Puptype == undefined ? '@/images/errtips.png' : "@/images/pupsuccessIcon.png")}"/><div class="messageTxt">${contxt}</div></div>`
    });
}
