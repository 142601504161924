
const info = {
    ZH: {  //中文翻译
        txt1: "新建画布",
        txt3: "提花机号",
        txt5: "开始设计",
        txt6: "请选择针床类型",
        txt7: "请选择提花机号",
        txt8: "请选择模式",
        txt9: "请选择",
        txt10: "单针床",
        txt11: "自由绘制",
        txt12: "图片导入",
        txt13: "新建",
        txt14: "画笔",
        txt15: "撤销",
        txt16: "导入图样",
        txt17: "纱线列表",
        txt18: "图样循环",
        txt19: "画布预览",
        txt20: "保存",
        txt21: "参数值",
        txt22: "画板：",
        txt23: "比例：",
        txt24: "宽",
        txt25: "高",
        txt26: "花型设计",
        txt27: "结构设计",
        txt28: "图层",
        txt29: "可支持8种色纱",
        txt30: "当前结构预览",
        txt31: "结构设计",
        txt32: "自定义",
        txt33: "结构库",
        txt34: "图样循环",
        txt35: "选择图样：",
        txt36: "请选择图样",
        txt37: "选择循环方式：",
        txt38: "缩略图：",
        txt39: "四方连续",
        txt40: "水平循环",
        txt41: "垂直循环",
        txt42: "关闭",
        txt43: "确定",
        txt44: "预览画布",
        txt45: "参数值",
        txt46: "针床类型：",
        txt47: "单针床",
        txt48: "提花机号：",
        txt49: "画布尺寸：",
        txt50: "纱线列表",
        txt51: "图样",
        txt52: "尺寸：",
        txt53: "提交成功",
        txt54: "继续织布",
        txt55: "织布列表",
        txt56: "结构设计",
        txt57: '取消渲染',
        txt58: "针床类型",
        txt59: "提花机号",
        txt60: "花宽",
        txt61: "花高",
        txt62: "绘制模式",
        txt63: "开始设计",
        txt64: '请输入花宽',
        txt65: '请输入花高',
        txt66: '不能大于100cm',
        txt67: "背景",
        txt68: "可用结构",
        txt69: "已应用",
        txt70: "编辑",
        txt71: "删除",
        txt72: "nao结构库",
        txt73: "编辑",
        txt74: "取消",
        txt75: "确定",
        txt76: '确认删除',
        txt77: '是否删除当前结构？',
        txt78: '请选择继续织布或查看织布列表',
        txt79: "版单",
        txt80: '宽度',
        txt81: '高度',
        txt82: '袜子码数范围为：36-44码',
        txt83: '预览 版单',
        txt84: '背景色：',
        txt85: '白色',
        txt86: '袜身色号：',
        txt87: '罗口色号：',
        txt88: '袜头色号：',
        txt89: '袜跟色号：',
        txt90: '背景色：',
        txt91: '袜身',
        txt92: '罗口',
        txt93: '袜头',
        txt94: '袜跟',
        txt95: '最近使用颜色：',
        txt96: '结构版单',
        txt97: '导入结构版单',
        txt98: '尺寸大小为',
        txt99: '背景展示',
        txt100: '结构颜色',
        txt101: '纱线颜色',
        txt102: '导出版单',
        txt103: "结构",
        txt104: "请先导入结构版单",
    },
    EN: {  //英文翻译
        txt1: "Create a New Canvas",
        txt2: "Needle Bed Type",
        txt3: "Jacquard Loom No.",
        txt4: "Mode",
        txt5: "Start",
        txt6: "Please choose a needle bed type",
        txt7: "Please choose a Jacquard Loom No.",
        txt8: "Please enter the mode",
        txt9: "Please Choose",
        txt10: "Single Needle Bed",
        txt11: "Free Drawing",
        txt12: "Image Import",
        txt13: "New",
        txt14: "Brush",
        txt15: "Undo",
        txt16: "Insert",
        txt17: "Yarn List",
        txt18: "Looping",
        txt19: "Preview",
        txt20: "Save",
        txt21: "Info",
        txt22: "Canvas：",
        txt23: "Ratio：",
        txt24: "Width",
        txt25: "Height",
        txt26: "Pattern Design",
        txt27: "Construction Design",
        txt28: "Pattern layers",
        txt29: "Up to 8 colors",
        txt30: "Current Construction Preview",
        txt31: "Structure Design",
        txt32: "Custom",
        txt33: "Structure Library",
        txt34: "Looping",
        txt35: "Pattern：",
        txt36: "Please Choose a Pattern",
        txt37: "Looping Method：",
        txt38: "Thumbnail：",
        txt39: "Four-Way Continuous",
        txt40: "Horizontal Loop",
        txt41: "Vertical Loop",
        txt42: "Cancel",
        txt43: "Confirm",
        txt44: "Preview",
        txt45: "Info",
        txt46: "Needle Bed Type：",
        txt47: "Single Needle Bed",
        txt48: "Jacquard Loom No.：",
        txt49: "Canvas Size：",
        txt50: "Yarn List",
        txt51: "Pattern",
        txt52: "Size：",
        txt53: "Submitted Successfully",
        txt54: "Continue knitting",
        txt55: "Weaving List",
        txt56: "structural design",
        txt57: 'Cancel rendering',
        txt58: "Needle Bed Type",
        txt59: "Jacquard Loom No.",
        txt60: "Width",
        txt61: "Height",
        txt62: "Drawing Mode",
        txt63: "Start",
        txt64: 'Please Enter Width',
        txt65: 'Please Enter Height',
        txt66: 'Cannot be larger than 100cm',
        txt67: "background",
        txt68: "Available Structures",
        txt69: "Applied",
        txt70: "Edit",
        txt71: "Delete",
        txt72: "Nao Construction Library",
        txt73: "Edit",
        txt74: "Cancel",
        txt75: "Confirm",
        txt76: 'Confirm Deletion',
        txt77: 'Do you want to delete current construction?',
        txt78: 'Please choose to continue weaving or view Weaving list',
        txt79: "Sheet",
        txt80: 'Width',
        txt81: 'Height',
        txt82: 'Sock Size Range: 36-44',
        txt83: 'Preview Sheet',
        txt84: 'Background Color：',
        txt85: 'White',
        txt86: 'Socks Color：',
        txt87: 'Socks Cuff Color：',
        txt88: 'Socks Toe Color：',
        txt89: 'Heel Color：',
        txt90: 'Background Color：',
        txt91: 'Socks Body',
        txt92: 'Socks Cuff',
        txt93: 'Socks Toe',
        txt94: 'Heel',
        txt95: 'Recently Used Colors：',
        txt96: 'Construction Sheet',
        txt97: 'Import Construction Sheet',
        txt98: 'File Size is',
        txt99: 'Background Display',
        txt100: 'Structure Color',
        txt101: 'Yarn Color',
        txt102: 'Export',
        txt103: "Structure",
        txt104: "Please Import the Structural Sheet First",
    }
}
export default info  //需要暴露出去