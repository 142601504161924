
const info = {
    ZH: {  //中文翻译
        txt1: "头像",
        txt2: "昵称",
        txt3: "NAO App",
        txt4: "NAO 公众号",
        txt5: "退出登录",
        txt6: '查看',
        txt7: "选择头像",
        txt8: "确认",
        txt9: "NAO 公众号",
        txt10: "微信扫一扫关注",
        txt11: "App Store 下载",
        txt12: "更换成功",
        txt13: "账号类型",
        txt14: "针织圆机网址",
        txt15: "NAO官网",
        txt16: "复制",
        txt17: "选取文件以上传",
        txt18: "取消",
        txt19: "上传",
        txt20: "编辑",
        txt21: "保存",
        txt22: "账号有效期至",
        txt23: "账号信息",
        txt24: "试用",
        txt25: "正式",
        txt26: "保存成功",
        txt27: "复制成功",
        txt28: "复制失败",
        txt29: "提花机网址",
        txt30: "袜子",
        txt31: "鞋子",
        txt32: "服装",
    },
    EN: {  //英文翻译
        txt1: "Profile Photo",
        txt2: "Full Name",
        txt3: 'App for iPhone',
        txt4: "NAO WeChat",
        txt5: "Exit",
        txt6: 'View',
        txt7: 'Choose a profile picture',
        txt8: "Confirm",
        txt9: "NAO Official WeChat Account",
        txt10: "Scan QR Code to Follow on WeChat",
        txt11: "App Store Download",
        txt12: "Replacement successful",
        txt13: "Account Type",
        txt14: "Circular knitting Machine site",
        txt15: "NAO Home Site",
        txt16: "Copy",
        txt17: "Select Files to Upload",
        txt18: "Cancel",
        txt19: "Upload",
        txt20: "Edit",
        txt21: "Save",
        txt22: "Expiration Date",
        txt23: "Account Information",
        txt24: "Trial",
        txt25: "Offical",
        txt26: "Successfully Saved",
        txt27: "Replicating Success",
        txt28: "Copy Failed",
        txt29: "Jacquard Site",
        txt30: "Socks",
        txt31: "Shoes",
        txt32: "Cloth",
    }
}
export default info  //需要暴露出去