
const info = {
    ZH: {  //中文翻译
        txt1: "登录",
        txt2: "虚拟织布机",
        txt3: "提花机",
        txt4: "手机号/邮箱",
        txt5: "密码",
        txt6: "请输入手机号/邮箱",
        txt7: "请输入密码",
        txt8: "请阅读并勾选",
        txt9: "《用户协议》",
        txt10: "无缝针织机",
        txt11: "请阅读并勾选《用户协议》",
        txt12: "NAO虚拟织布机用户协议",
    },
    EN: {  //英文翻译
        txt1: "Log in",
        txt2: "VIRTUAL KNITTING MACHINE",
        txt3: 'Jacquard Loom',
        txt4: "Phone Number/Email",
        txt5: "Password",
        txt6: "Please enter Phone Number/Email",
        txt7: "Please enter password",
        txt8: "Please read and check the ",
        txt9: "User Agreement",
        txt10: "Seamless Machine",
        txt11: "Please read and check the User Agreement",
        txt12: "User Agreement for NAO Virtual Knitting Machine",
    }
}
export default info  //需要暴露出去