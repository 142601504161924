// import loginUp from "@/views/login/loginUp/loginUp";
// import ForgetPassword from "@/views/login/ForgetPassword/ForgetPassword";
// import newuserRegister from "@/views/login/newuserRegister/newuserRegister";
// import userIndex from "@/views/userInfo/userIndex/userIndex";
const loginUp = () => import("@/views/login/loginUp/loginUp");
const userAgreement = () => import("@/views/login/userAgreement/userAgreement");
const userIndex = () => import("@/views/userInfo/userIndex/userIndex");
const modelSwitching = () => import("@/views/login/modelSwitching/modelSwitching"); //渲染失败页面

const router = [
  {
    path: "/loginUp",
    name: "loginUp",
    component: loginUp,
    meta: { tabbar: false, title: "登录" },
  },
  {
    path: "/",
    name: "modelSwitching",
    component: modelSwitching,
    meta: { tabbar: true, title: "机型选择" },
  },
  {
    path: "/modelSwitching",
    name: "modelSwitching",
    component: modelSwitching,
    meta: { tabbar: true, title: "机型选择" },
  },
  {
    path: "/userIndex",
    name: "userIndex",
    component: userIndex,
    meta: { tabbar: true, title: "个人中心" },
  },
  {
    path: "/userAgreement",
    name: "userAgreement",
    component: userAgreement,
    meta: { tabbar: false, title: "用户协议" },
  },
];
//抛出模块
export default router;
