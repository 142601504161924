<template>
  <div class="popup" v-show="type != 0">
    <div class="mask" @click="cancelDialog" v-show="type != 0"></div>
    <div class="appPopupContent" v-show="type == 1">
      <div class="top">
        <span>NAO App</span>
      </div>
      <div class="cancleBtn" @click="cancelDialog">
        <img src="@/images/Close.png" alt="" />
      </div>
      <div class="appContent">
        <img class="appImg" src="@/newImg/app.png" alt="" />
        <div class="title">
          <span>{{ $t("User.txt11") }}</span>
        </div>
      </div>
    </div>
    <div class="OAPopupContent" v-show="type == 2">
      <div class="top">
        <span>{{ $t("User.txt9") }}</span>
      </div>
      <div class="cancleBtn" @click="cancelDialog">
        <img src="@/images/Close.png" alt="" />
      </div>
      <div class="OAContent">
        <img class="OAImg" src="@/images/OfficialAccountsImg.png" alt="" />
        <div class="title">
          <span>{{ $t("User.txt10") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "pupAppShow",
  props: ["type"],
  data() {
    return {};
  },
  methods: {
    cancelDialog() {
      this.$emit("result", false);
    },
  },
};
</script>
<style lang="less">
@import "./pupAppShow.less";
</style>