
const info = {
    ZH: {  //中文翻译
        txt1: "宽度：",
        txt2: "高度：",
        txt3: "生成宽高",
        txt4: "清屏",
        txt5: "满屏",
        txt7: "成圈",
        txt8: "集圈",
        txt9: "浮线",
        txt10: "结构记录",
        txt11: "结构-",
        txt12: "新建画布面信息将会丢失，是否继续？",
        txt13: "关闭",
        txt14: "确定",
        txt15: "当前画布色纱总数为10色，已达到最大限度",
        txt16: "请删除当前图片图层，或合并纱线颜色",
        txt17: "方可再次导入图片！",
        txt18: "序号",
        txt19: "颜色",
        txt20: "色号",
        txt21: "成分",
        txt22: "纱支",
        txt24: "棉",
        txt25: "人造棉",
        txt26: "莫代尔",
        txt27: "来赛尔",
        txt28: "竹纤维",
        txt29: "亚麻",
        txt30: "蚕丝",
        txt31: "羊毛",
        txt32: "羊绒",
        txt33: "涤纶",
        txt34: "锦纶",
        txt35: "醋酸",
        txt36: "腈纶",
        txt37: "丙纶",
        txt38: "氨纶",
        txt39: "成分",
        txt40: "（注：成分比例和为100%）",
        txt41: "纱支",
        txt42: "纱线类型：",
        txt43: "纯纺",
        txt44: "混纺",
        txt45: "成分：",
        txt46: "请选择",
        txt47: "请选择成分",
        txt48: "混纺数量：",
        txt49: "请选择混纺数量",
        txt50: "序号",
        txt51: "成分",
        txt52: "比例",
        txt53: "数值：",
        txt54: "单位：",
        txt55: "请输入",
        txt56: "请确保成分比例和为100%",
        txt57: "应用",
        txt58: '结构',
        txt59: '预览',
        txt60: '修改尺寸',
        txt61: '源图',
        txt62: '宽：',
        txt63: '高：',
        txt64: '修改尺寸',
        txt65: '下一步',
        txt66: '画布尺寸：',
        txt67: '源图尺寸：',
        txt68: '调整尺寸',
        txt69: '保持比例',
        txt70: '颜色转化',
        txt71: '转化后',
        txt72: '减少颜色',
        txt73: '上一步',
        txt74: '转化中',
        txt75: '最大可支持图片解析色纱总数为',
        txt76: '色',
        txt77: "请先导入花型图",
        txt78: "进入结构定义面板，则花型图无法再次编辑 请确定是否继续进行结构设计？",
        txt79: '画布尺寸',
        txt80: '取消',
        txt81: '确认',
        txt82: '添加',
        txt83: '确认保存',
        txt84: '应用在画布中的结构参数将会被覆盖，是否继续？',
        txt85: '导入结构版单',
    },
    EN: {  //英文翻译
        txt1: "Width:",
        txt2: "Height:",
        txt3: 'Generate',
        txt4: "Clear",
        txt5: "Full screen",
        txt7: "Knit",
        txt8: "Tuck",
        txt9: "Welt",
        txt10: "Structural records",
        txt11: "Structure-",
        txt12: "Creating a new canvas will result in the loss of current page information. Do you want to proceed?",
        txt13: "Cancel",
        txt14: "Confirm",
        txt15: "The current canvas has reached the maximum limit of 10 thread colors.",
        txt16: "Please delete the current image color layers or merge thread colors before importing the",
        txt17: "image again.",
        txt18: "Serieal No.",
        txt19: "Color",
        txt20: "Color Code",
        txt21: "Component",
        txt22: "Yarn Count",
        txt24: "Cotton",
        txt25: "Synthetic cotton",
        txt26: "Modal",
        txt27: "Spandex",
        txt28: "Bamboo fiber",
        txt29: "Linen",
        txt30: "Silk",
        txt31: "Wool",
        txt32: "Cashmere",
        txt33: "Polyester",
        txt34: "Polyamide",
        txt35: "Acetic acid",
        txt36: "Acrylic fiber",
        txt37: "Polypropylene fiber",
        txt38: "Elastane",
        txt39: "Component ",
        txt40: "(Note: The sum of component proportions is 100%.)",
        txt42: "Yarn Type：",
        txt43: "Pure Spun",
        txt44: "Blended",
        txt45: "Component：",
        txt46: "Please Choose ",
        txt47: "Please Choose Component",
        txt48: "Blended Quantity：",
        txt49: "Please Choose Blended Quantity：",
        txt50: "Serieal No.",
        txt51: "Component",
        txt52: "Ratio",
        txt53: "Count：",
        txt54: "Unit：",
        txt55: "Please enter",
        txt56: "Please make sure that the component ratios add up to 100%.",
        txt57: 'Apply',
        txt58: 'Structure',
        txt59: 'Preview',
        txt60: 'Edit Pattern Size',
        txt61: 'Source Pattern',
        txt62: 'Width：',
        txt63: 'Height：',
        txt64: 'Edit',
        txt65: 'Next',
        txt66: 'Canvas Size：',
        txt67: 'Source Pattern Size',
        txt68: 'Edit Pattern Size',
        txt69: 'Keep ratio',
        txt70: 'Color Conversion',
        txt71: 'After',
        txt72: 'Reduce Color',
        txt73: 'Previous',
        txt74: 'Transforming',
        txt75: 'Supports the current image resolution with a total of',
        txt76: 'thread colors',
        txt77: "Please import the pattern diagram first",
        txt78: "After entering the structure definition panel, the pattern drawing cannot be edited again. Are you sure you want to continue with the structural design?",
        txt79: 'Canvas Size',
        txt80: 'Cancel',
        txt81: 'Confirm',
        txt82: 'Add',
        txt83: 'Confirm Save',
        txt84: 'Applying the structure parameters in the canvas will overwrite the existing ones, continue?',
        txt85: 'Import Construction Sheet',
    }
}
export default info  //需要暴露出去