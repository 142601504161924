const History = () => import("@/views/History/index");
const THHistoryDetail = () =>
  import("@/views/History/THHistoryDetail/THHistoryDetail");
// const HistoryDetail = () =>
  // import("@/views/History/HistoryDetail/HistoryDetail");
// const hzHistoryDetail = () =>
  // import("@/views/History/HZHistoryDetail/HZHistoryDetail");
// const DownLoadPage = () => import("@/views/History/DownloadPage/DownloadPage");
const THDownLoadPage = () => import("@/views/History/THDownloadPage/THDownloadPage");
// const HZDownLoadPage = () => import("@/views/History/HZDownloadPage/HZDownloadPage");
// const IntegratedHistoryDetail = () => import("@/views/History/IntegratedHistoryDetail/IntegratedHistoryDetail");
// const IntegratedDownloadPage = () => import("@/views/History/IntegratedDownloadPage/IntegratedDownloadPage");
const testIndex = () => import("@/views/History/component/index.vue");


const router = [
  {
    path: "/history",
    component: History,
    name: "history",
    meta: { tabbar: true, title: "历史" },

    children: [
      /* {
        path: "downloadpage/",
        name: "downloadpage",
        component: DownLoadPage,
        meta: { tabbar: true, title: "下载" },
      }, */
      /* {
        path: "historyDetail/:id",
        component: HistoryDetail,
        name: "historyDetail",
        meta: { tabbar: true, title: "详情" },
      }, */
      {
        path: "thhistoryDetail/:id",
        component: THHistoryDetail,
        name: "thhistoryDetail",
        meta: { tabbar: true, title: "提花详情" },
      },
      {
        path: "thdownloadpage/",
        component: THDownLoadPage,
        name: "thdownloadpage",
        meta: { tabbar: true, title: "提花下载" },
      },
      /* {
        path: "hzHistoryDetail/:id",
        component: hzHistoryDetail,
        name: "hzHistoryDetail",
        meta: { tabbar: true, title: "染整详情" },
      },
      {
        path: "HZDownLoadPage/",
        component: HZDownLoadPage,
        name: "HZDownLoadPage",
        meta: { tabbar: true, title: "染整下载" },
      },
      {
        path: "IntegratedHistoryDetail/:id",
        component: IntegratedHistoryDetail,
        name: "IntegratedHistoryDetail",
        meta: { tabbar: true, title: "一体机详情" },
      },
      {
        path: "IntegratedDownloadPage/",
        component: IntegratedDownloadPage,
        name: "IntegratedDownloadPage",
        meta: { tabbar: true, title: "一体机下载" },
      }, */
      {
        path: "testIndex/",
        component: testIndex,
        name: "testIndex",
        meta: { tabbar: true, title: "test" },
      },
    ],
  },
];
//抛出模块
export default router;
