/*
 * @Description: 请求方法简易封装
 * @Date: 2021-07-20 12:20:39
 * @LastEditTime: 2022-01-21 09:52:19
 * @FilePath: \web-site\src\config\request.js
 */
import axios from "axios";
import { getToken } from "@/utils/utils";
import VueRouter from "@/router/router";
import { setHttp } from "./baseURL";
// 创建axios实例
export const service = axios.create({
  timeout: 1000000, // 请求超时
  baseURL: `${process.env.VUE_APP_BASE_URL}`, // api的基本地址
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 上传文件
    if (config.method.toLowerCase() === "post") {
      config.headers["token"] = getToken();
      // config.headers['Content-Type']='multipart/form-data' 
      let copyData
      if (config.data == undefined) {
        copyData = {
          languageSet: 1
        }
      } else {
        copyData = JSON.parse(JSON.stringify(config.data))
      }
      if (localStorage.getItem('languageSet') != null) {
        copyData.languageSet = localStorage.getItem('languageSet') == "zh" ? 1 : 2
      }
      config.data = copyData
    }
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    const { data, status } = response;
    if (status === 200) {
      // token失效处理，和后端约定返回码, 比如410是token失效
      if (data.code == 401) {
        VueRouter.push("/loginUp");
      }
      return data;
    } else {
      // 处理http错误，抛到业务代码
    }
    return Promise.reject(response);
  },
  (error) => {
    // 对响应错误做点什么, 错误抛到业务代码
    error.data = {};
    error.data.msg = "请求超时或服务器异常，请检查网络或联系管理员！";
    return Promise.resolve(error);
  }
);

/**
 * post方法，对应post请求
 * @param { String } url [请求的url地址]
 * @param { Object } params [请求时携带的参数]
 */
export const POST = (url, params) => {
  const sendData = params || {};
  return service.post(url, params);
};

/**
 * get方法，对应get请求
 * @param { String } url [请求的url地址]
 * @param { Object } params [请求时携带的参数]
 */
export const GET = (url, params) => {
  const sendData = {};
  sendData.data = params || {};
  return service.get(url, { params });
};
