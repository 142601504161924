/*
 * @Description: 统一管理所有接口
 * @Date: 2021-07-20 12:20:39
 * @LastEditTime: 2021-07-31 10:50:57
 * @FilePath: \web-site\src\service\api.js
 */
export default {
  /*
   * 首页数据源列表
   */

  api_res: "/design/res",
  api_result: "/design/result",
  api_save: "/design/save",
  api_thsave: "/design/save",

  api_saveCloth: "/drapery/add",
  api_saveThCloth: "/drapery/aioAdd",
  api_backRes: "/backRes",
  api_upRenderImg: "/backResImg",
  api_upRenderUrl: "backResImgSave",
  api_clothesSmallSave: "/design/clothesSmallSave", //一体机上传最小循环数据
  api_clothesArray: "/design/clothesArray", //获取一体机上传最小循环图片
  api_clothesSave: "/design/clothesSave", //获取一体机上衣id
  api_clothesAdd: "/drapery/clothesAdd", //保存一体机上衣


  /*
   * 登陆和用户相关
   */
  api_login: "/login", // 登录
  api_getMessage: "/getMessage", //获取短信验证码
  api_userRegister: "/addUser", //用户注册
  api_setPassword: "/resetPassword", //重置密码
  api_getEmailMessage: "/getEmail", //获取邮箱验证码
  api_getUserInfo: "/user/Info", //获取个人信息
  api_userInfoEdit: "/user/edit", //个人信息修改
  api_userSafe: "/user/safe", //安全设置信息
  api_userSafeEdit: "/user/safeEdit", //安全设置信息修改
  api_userBindPhone: "/user/bindPhone", //绑定手机号
  api_userBindEmail: "/user/bindEmail", //绑定邮箱
  api_userUnboundPhone: "/user/unboundPhone", //解绑手机号
  api_userUnboundEmail: "/user/unboundEmail", //解绑邮箱
  api_uploadUserImg: "/user/alyImg", //上传头像
  api_userLoginOut: "/user/logout", //退出登录
  api_checkVCode: "/user/checkCode", //更改邮箱或手机号之前先验证原邮箱或手机号
  api_getMachine: "/enum/list", //获取机器类型
  api_loadImg: "/upFile", //上传file图片
  api_bindUserName: "/user/bindUserName", //上传file图片

  /*
   * 纱线数据列表
   */
  api_index_storeList: "/store/list", //获取纱线
  api_yarnDetail: "/store/all", //纱线详情
  api_addYarnStore: "/store/add", //纱线库新增
  api_editYarnStore: "/store/edit", //纱线编辑
  api_delYarnStore: "/store/del", //纱线删除
  api_publicYarnStore: "store/common", //公共纱线库
  api_getRenderStatus: "/drapery/getRenderStatus", //刷新接口数据

  api_commonYarnList: "yarn/commonLibraryList",// 公共纱线库  纯纺纱
  api_personalYarnList: "yarn/userYarnLibraryList",// 个人纱线库  纯纺纱
  api_addColorSeries: "yarn/addColorSeries",// 新增纱线颜色系列
  api_userColorData: "yarn/userColorData",// 个人颜色系列资料列表
  api_userColorDataDetail: "yarn/userColorDataDetail",// 个人颜色系列资料详情
  api_delUserYarn: "/yarn/delUserYarnLibrary",// 个人纱线库删除纱线
  api_delUserYarnLibrary: "yarn/delYarnUserColor",// 个人纱线库删除
  api_addUserYarnLibrary: "yarn/addUserYarnLibrary",// 个人纱线库添加
  api_delYarnUserColorDetail: "yarn/delYarnUserColorDetail",// 个人纱线库添加
  api_editColorSeries: "yarn/editColorSeries",// 编辑颜色系列
  api_usedYarnColor: "yarn/usedYarnColor",// 已使用颜色标记
  /*
   * 历史数据列表
   */
  api_searchHistory: "/drapery/list", //织布历史查询
  api_editHistory: "/drapery/edit", //织布历史修改
  api_delHistory: "/drapery/del", //织布历史删除
  api_downHistory: "/drapery/download", //织布历史下载
  api_getClothDetail: "/drapery/detail", //获取布料详情
  api_getClothExportSelect: "/drapery/exportSelect", //获取布料导出选择
  api_getWeaveCategoryList: "/weave/weaveCategoryList", //获取织法类目数据
  /*
  * 后整功能请求
  */
  api_UploadIamge: "/backImgs", //后整上传套色
  api_laterSave: "/later/save", //后整baocun

  /*
  * 类目功能请求
  */
  api_getWeaveList: "/weave/getWeaveList", //获取当前本级类目列表
  api_addWeaveCategory: "/weave/addWeaveCategory", //添加类目
  api_editWeaveParam: "/weave/editWeaveParam", //编辑类目
  api_delWeaveData: "/weave/delWeaveData", //删除类目
  api_weaveCategoryTree: "/weave/weaveCategoryTree", //树状图数组
};
