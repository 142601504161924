const jacquardWeaveinlet = () => import("@/views/newWeave/oneIndex/oneIndex"); //渲染失败页面



const router = [
  {
    path: "/oneIndex",
    component: jacquardWeaveinlet,
    name: "index",
    meta: {
      tabbar: true,
      title: "机型选择",
      keepAlive: true
    },
  },
];

//抛出模块
export default router;