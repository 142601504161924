
const info = {
    ZH: {  //中文翻译
        txt1: "保存",
        txt2: "织物工艺详情",
        txt3: "个人中心",
        txt4: "效果图",
        txt5: "参数信息",
        txt6: "面料尺寸",
        txt7: "横密",
        txt8: "纵密",
        txt9: "几何体直径",
        txt10: "保存面料",
        txt11: "面料名称",
        txt12: "请输入面料名称",
        txt13: "取消",
        txt14: "确定",
        txt15: "此块面料已保存！",
        txt16: "请选择继续织布或查看织布列表",
        txt17: "继续织布",
        txt18: "织布列表",
        txt19: "无法渲染此块布料",
        txt20: "无法渲染此块布料，正在诊断该问题。",
        txt21: "重新渲染",
    },
    EN: {  //英文翻译
        txt1: "Save",
        txt2: "Fabric Crafting Details",
        txt3: 'Personal Center',
        txt4: 'Illustration',
        txt5: "Parameter Information",
        txt6: "Canvas Size",
        txt7: "Horizontal Density",
        txt8: "Vertical Density",
        txt9: "Sphere Diameter",
        txt10: "Saving Fabric",
        txt11: "Fabric name",
        txt12: "Please enter fabric name",
        txt13: "Cancel",
        txt14: "Confirm",
        txt15: "The fabric has been saved!",
        txt16: "Please choose to continue knitting or view fabric list",
        txt17: "Continue knitting",
        txt18: "Fabric list",
        txt19: "Unable to render this piece of cloth",
        txt20: "This cloth cannot be rendered. The problem is being diagnosed.",
        txt21: "Re-render",
    }
}
export default info  //需要暴露出去