
const info = {
    ZH: {  //中文翻译
        txt1: "请选择机器类型",
        txt2: "单针道",
        txt3: "双面（2+4）针道",
        txt4: "双面（4+4）针道",
        txt5: "确定",
    },
    EN: {  //英文翻译
        txt1: "Please select a type",
        txt2: "Single-Needle Track",
        txt3: 'Double-Needle (2+4) Track',
        txt4: "Double-Needle (4+4) Track",
        txt5: "Confirm",
    }
}
export default info  //需要暴露出去